import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IMeta } from '../api/models/profile/models'
import { RootState } from '../store/store'

export interface IProfileSettingsSlice {
    verificationEmailMeta: IMeta
    verificationPhoneMeta: IMeta
}

const initialState: IProfileSettingsSlice = {
    verificationEmailMeta: {
        resend_after: 0,
        otp_length: 0,
        interval: 0,
        ttl: 0,
    },
    verificationPhoneMeta: {
        resend_after: 0,
        otp_length: 0,
        interval: 0,
        ttl: 0,
    },
}

const settings = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        getVerificationEmailMeta: (state, action: PayloadAction<IMeta>) => {
            state.verificationEmailMeta = action.payload
        },
        getVerificationPhoneMeta: (state, action: PayloadAction<IMeta>) => {
            state.verificationPhoneMeta = action.payload
        },
    },
})

export const { getVerificationEmailMeta, getVerificationPhoneMeta } =
    settings.actions
export const verificationEmailMeta = (state: RootState) =>
    state.settings.verificationEmailMeta
export const verificationPhoneMeta = (state: RootState) =>
    state.settings.verificationPhoneMeta

export default settings.reducer
