import { motion } from 'framer-motion'
import { FC } from 'react'
import { Button } from '../../../core/components/common/Button/Button'
import profileImage from '../../assets/images/profileImage.png'
import chartImage from '../../assets/images/chartImage.svg'
import jobregImage from '../../assets/images/jobregImage.svg'
import company1Image from '../../assets/images/company1Image.svg'
import company2Image from '../../assets/images/company2Image.svg'
import company3Image from '../../assets/images/company3Image.svg'

export const SectionCompanies: FC = () => {
    return (
        <div className="container mx-auto flex items-center justify-between flex-col md:flex-row">
            <div className="md:w-[320px] lg:w-[400px] xl:w-[480px]">
                <h2 className="text-[32px] text-center md:text-left lg:text-[40px] font-semibold text-white">
                    RecMan for Companies
                </h2>
                <p className="mt-4 text-center md:text-left md:mt-8 text-lg text-white">
                    Use RecMan to access candidates in the Jobreg database and
                    use RecMan for posting jobs and recruiting employees.
                </p>
                <div className="w-[200px] mt-8 md:mt-[48px] mx-auto md:mr-auto md:ml-0">
                    <Button
                        type={'button'}
                        view={'primary'}
                        title={'View Details'}
                        onClick={() =>
                            (location.href = `${process.env.API_URL}/available-jobs`)
                        }
                    />
                </div>
            </div>
            <div className="relative md:ml-[50px] w-[250px] h-[250px] mt-[60px] md:mt-0 md:w-[380px] md:h-[380px] lg:w-[528px] lg:h-[528px]">
                <motion.div
                    initial={'rest'}
                    whileHover={'hover'}
                    animate={'rest'}
                    className={
                        'w-full h-full md:scale-[0.65] lg:scale-[0.71] xl:scale-100'
                    }
                >
                    <motion.div
                        variants={Circle1Motion}
                        className="absolute left-0 bg-[#9279F8] rounded-full w-[250px] h-[250px] md:w-[380px] md:h-[380px] lg:w-[528px] lg:h-[528px]"
                    />
                    <motion.div
                        variants={Circle2Motion}
                        className="absolute left-0 bg-[#9279F8] opacity-50 rounded-full w-[250px] h-[250px] md:w-[380px] md:h-[380px] lg:w-[528px] lg:h-[528px]"
                    />
                    <motion.div
                        variants={Circle3Motion}
                        className="absolute left-0 bg-[#9279F8] opacity-20 rounded-full w-[250px] h-[250px] md:w-[380px] md:h-[380px] lg:w-[528px] lg:h-[528px]"
                    />
                    <motion.div
                        variants={jobregMotion}
                        className={
                            'absolute w-[80px] -right-[60px] top-[25px] md:-right-[70px] md:top-[25px] lg:right-0 lg:top-0 md:w-[120px] lg:w-auto'
                        }
                    >
                        <img src={jobregImage} />
                    </motion.div>
                    <motion.div
                        variants={profileImageMotion}
                        className="absolute left-0 -top-[25px] md:top-0"
                    >
                        <img src={profileImage} />
                    </motion.div>
                    <motion.div
                        variants={chartMotion}
                        className={
                            'absolute w-[75px] left-[50px] -bottom-[25px] md:left-[40px] lg:left-0 bottom-0 md:w-[100px] lg:w-auto'
                        }
                    >
                        <img src={chartImage} />
                    </motion.div>
                    <motion.div
                        variants={company1Motion}
                        className={
                            'absolute w-[120px] -right-[50px] -top-[25px] md:-right-[75px] lg:right-0 top-0 md:w-[180px] lg:w-auto'
                        }
                    >
                        <img src={company3Image} />
                    </motion.div>
                    <motion.div
                        variants={company2Motion}
                        className={
                            'absolute w-[120px] -right-[50px] -top-[55px] md:-right-[75px] lg:right-0 top-0 md:w-[180px] lg:w-auto'
                        }
                    >
                        <img src={company2Image} />
                    </motion.div>
                    <motion.div
                        variants={company3Motion}
                        className={
                            'absolute w-[120px] -right-[50px] -top-[85px] md:-right-[75px] lg:right-0 top-0 md:w-[180px] lg:w-auto'
                        }
                    >
                        <img src={company1Image} />
                    </motion.div>
                </motion.div>
            </div>
        </div>
    )
}

const Circle1Motion = {
    rest: {
        x: 0,
        y: 0,
    },
    hover: {
        x: 0,
        y: 0,
        transition: {
            duration: 0.5,
        },
    },
}

const Circle2Motion = {
    rest: {
        x: 0,
        y: 0,
    },
    hover: {
        x: 24,
        y: -48,
        transition: {
            duration: 0.5,
        },
    },
}

const Circle3Motion = {
    rest: {
        x: 0,
        y: 0,
    },
    hover: {
        x: 64,
        y: 40,
        transition: {
            duration: 0.5,
        },
    },
}

const profileImageMotion = {
    rest: {
        x: 0,
        y: 70,
    },
    hover: {
        x: 0,
        y: 70,
        transition: {
            duration: 0.5,
        },
    },
}

const chartMotion = {
    rest: {
        x: -40,
        y: -110,
    },
    hover: {
        x: -40,
        y: -110,
        transition: {
            duration: 0.5,
        },
    },
}

const jobregMotion = {
    rest: {
        x: -120,
        y: -45,
    },
    hover: {
        x: -180,
        y: -61,
        transition: {
            duration: 0.5,
        },
    },
}

const company1Motion = {
    rest: {
        x: -25,
        y: 85,
    },
    hover: {
        x: -25,
        y: 65,
        transition: {
            duration: 0.5,
        },
    },
}

const company2Motion = {
    rest: {
        x: -25,
        y: 180,
    },
    hover: {
        x: -25,
        y: 220,
        transition: {
            duration: 0.5,
        },
    },
}

const company3Motion = {
    rest: {
        x: -25,
        y: 275,
    },
    hover: {
        x: -25,
        y: 375,
        transition: {
            duration: 0.5,
        },
    },
}
