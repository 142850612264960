import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IMetaSMS } from '../api/models/signup/models'
import { RootState } from '../store/store'

export interface ISignUpSlice {
    steps: string[]
    currentStep: string
    password_reset_flow_uuid: string
    meta: IMetaSMS
    email: string
    link: string
    token: string
}

const initialState: ISignUpSlice = {
    steps: ['enter_email', 'otp_confirmation', 'password_reset', 'end'],
    currentStep: 'enter_email',
    password_reset_flow_uuid: '',
    meta: {},
    email: '',
    link: '',
    token: '',
}

const forgotPasswordSlice = createSlice({
    name: 'forgotPassword',
    initialState,
    reducers: {
        prevStep: (state) => {
            if (state.currentStep !== 'enter_email') {
                const idx = state.steps.indexOf(state.currentStep)
                state.currentStep = state.steps[idx - 1]
            } else {
                window.location.href = '/login'
            }
        },
        setResetUuid: (state, action: PayloadAction<string>) => {
            state.password_reset_flow_uuid = action.payload
        },
        setMetaSMS: (state, action: PayloadAction<IMetaSMS>) => {
            state.meta = action.payload
        },
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload
        },
        nextStep: (state, action: PayloadAction<string>) => {
            state.currentStep = action.payload
        },
        setRedirectLink: (state, action: PayloadAction<string>) => {
            state.link = action.payload
        },
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload
        },
    },
})

export const {
    setResetUuid,
    setMetaSMS,
    setEmail,
    nextStep,
    setRedirectLink,
    prevStep,
    setToken,
} = forgotPasswordSlice.actions
export const currentStep = (state: RootState) =>
    state.forgotPassword.currentStep
export const email = (state: RootState) => state.forgotPassword.email
export const meta = (state: RootState) => state.forgotPassword.meta
export const link = (state: RootState) => state.forgotPassword.link
export const uuid = (state: RootState) =>
    state.forgotPassword.password_reset_flow_uuid
export const token = (state: RootState) => state.forgotPassword.token
export default forgotPasswordSlice.reducer
