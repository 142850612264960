import { FC } from 'react'
import IconDropdown from '../../../assets/icons/IconDropdown.svg'
import IconDropdownGray from '../../../assets/icons/IconDropdownGray.svg'

interface ISelectArrow {
    position: boolean
    disabled?: boolean
}

export const SelectArrow: FC<ISelectArrow> = ({ position, disabled }) => {
    return (
        <span
            className={`w-1.5 h-1 transform ${position ? 'rotate-180' : null}`}
        >
            {disabled ? (
                <img src={IconDropdownGray} alt="dropdown" />
            ) : (
                <img src={IconDropdown} alt="dropdown" />
            )}
        </span>
    )
}
