import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { forgotPasswordApi } from '../api/forgotPasswordApi';
import { signupApi } from '../api/signupApi';
import signupReducer from '../slices/signup';
import profileReducer from '../slices/profile';
import forgotPasswordReducer from '../slices/forgotPassword';
import settingsReducer from '../slices/settings'
import availableJobsReducer from '../slices/availableJobs'
import { loginApi } from '../api/loginApi';
import { profileApi } from '../api/profileApi';
import { availableJobsApi } from '../api/availableJobs';
import { policyApi } from '../api/policyApi';

export const store = configureStore({
  reducer: {
    signup: signupReducer,
    forgotPassword: forgotPasswordReducer,
    profile: profileReducer,
    settings: settingsReducer,
    availableJobs: availableJobsReducer,
    [signupApi.reducerPath]: signupApi.reducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [forgotPasswordApi.reducerPath]: forgotPasswordApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [availableJobsApi.reducerPath]: availableJobsApi.reducer,
    [policyApi.reducerPath]: policyApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(signupApi.middleware, loginApi.middleware, forgotPasswordApi.middleware, profileApi.middleware, availableJobsApi.middleware, policyApi.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
