import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IMeta } from '../api/models/profile/models'
import { RootState } from '../store/store'

export interface IProfileSlice {
    scrollToBlockName: string
    profileEmail: string
    verificationMeta: IMeta
    availableForWorkNotify: boolean
    isMobile: boolean | null
    isTablet: boolean | null
    isTabletAir: boolean | null
    tab: string
    required_blocks_filled: boolean
    profileName: string
    profileAvatar: string
}

const initialState: IProfileSlice = {
    scrollToBlockName: '',
    profileEmail: '',
    verificationMeta: {
        resend_after: 0,
        otp_length: 0,
        interval: 0,
        ttl: 0,
    },
    availableForWorkNotify: false,
    isMobile: window.innerWidth < 640,
    isTablet: window.innerWidth < 1025,
    isTabletAir: window.innerWidth < 992,
    tab: 'profile',
    required_blocks_filled: false,
    profileName: '',
    profileAvatar: '',
}

const profile = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setRequiredBlocksFilled: (state, action: PayloadAction<boolean>) => {
            state.required_blocks_filled = action.payload
        },
        setActiveTab: (state, action: PayloadAction<string>) => {
            state.tab = action.payload
        },
        getScrollToBlockName: (state, action: PayloadAction<string>) => {
            console.log(action.payload)
            if (
                action.payload === 'work_experience' ||
                action.payload === 'education' ||
                action.payload === 'skill' ||
                action.payload === 'title' ||
                action.payload === 'driver_license' ||
                action.payload === 'language' ||
                action.payload === 'project_experience' ||
                action.payload === 'avatar' ||
                action.payload === 'certificate' ||
                action.payload === 'address' ||
                action.payload === 'about_me'
            ) {
                state.tab = 'profile'
            } else {
                state.tab = 'options'
            }

            state.scrollToBlockName = action.payload
        },
        setProfileEmail: (state, action: PayloadAction<string>) => {
            state.profileEmail = action.payload
        },
        setMetaForEmailValidation: (state, action: PayloadAction<IMeta>) => {
            state.verificationMeta = action.payload
        },
        setAvailableForWorkNotify: (state, action: PayloadAction<boolean>) => {
            state.availableForWorkNotify = action.payload
        },
        setProfileName: (state, action: PayloadAction<string>) => {
            state.profileName = action.payload
        },
        setProfileAvatar: (state, action: PayloadAction<string>) => {
            state.profileAvatar = action.payload
        },
        setNewDimension: (state, action: PayloadAction<number>) => {
            if (action.payload < 640) {
                state.isMobile = true
                state.isTabletAir = false
                state.isTablet = false
            } else if (action.payload > 640 && action.payload < 992) {
                state.isTabletAir = true
                state.isMobile = false
                state.isTablet = false
            } else if (action.payload > 992 && action.payload < 1026) {
                state.isTablet = true
                state.isMobile = false
                state.isTabletAir = false
            } else {
                state.isMobile = false
                state.isTabletAir = false
                state.isTablet = false
            }
        },
    },
})

export const {
    getScrollToBlockName,
    setProfileEmail,
    setMetaForEmailValidation,
    setAvailableForWorkNotify,
    setActiveTab,
    setRequiredBlocksFilled,
    setProfileName,
    setProfileAvatar,
    setNewDimension,
} = profile.actions
export const scrollToBlockName = (state: RootState) =>
    state.profile.scrollToBlockName
export const profileEmail = (state: RootState) => state.profile.profileEmail
export const verificationMeta = (state: RootState) =>
    state.profile.verificationMeta
export const availableForJobNotify = (state: RootState) =>
    state.profile.availableForWorkNotify
export const isMobile = (state: RootState) => state.profile.isMobile
export const isTablet = (state: RootState) => state.profile.isTablet
export const isTabletAir = (state: RootState) => state.profile.isTabletAir
export const tab = (state: RootState) => state.profile.tab
export const required_blocks_filled = (state: RootState) =>
    state.profile.required_blocks_filled
export const profileName = (state: RootState) => state.profile.profileName
export const profileAvatar = (state: RootState) => state.profile.profileAvatar
export default profile.reducer
