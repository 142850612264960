// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nBFpAfe8Kw94Y5YoUzrr {\n    background: #fff;\n    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.08);\n    border-bottom-left-radius: 4px;\n    border-bottom-right-radius: 4px;\n    max-height: 300px;\n    overflow-y: auto;\n    width: 300px;\n}\n\n@media (max-width: 640px) {\n    .nBFpAfe8Kw94Y5YoUzrr {\n        max-height: 144px;\n    }\n}", "",{"version":3,"sources":["webpack://./core/components/common/Select/Select.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,4CAA4C;IAC5C,8BAA8B;IAC9B,+BAA+B;IAC/B,iBAAiB;IACjB,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".selectList {\n    background: #fff;\n    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.08);\n    border-bottom-left-radius: 4px;\n    border-bottom-right-radius: 4px;\n    max-height: 300px;\n    overflow-y: auto;\n    width: 300px;\n}\n\n@media (max-width: 640px) {\n    .selectList {\n        max-height: 144px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectList": "nBFpAfe8Kw94Y5YoUzrr"
};
export default ___CSS_LOADER_EXPORT___;
