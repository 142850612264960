import { FC, forwardRef, useEffect, useState } from 'react'
import { IInput } from './Input.model'

export const Input = forwardRef(
    (
        {
            type,
            onChange,
            onBlur,
            onError,
            placeholder,
            id,
            label,
            icon,
            action,
            value,
            className,
            disabled,
            focusOn,
            iconPosition,
            iconClassName,
            autocomplete = 'on',
            onKeyDown
        }: IInput,
        ref: any
    ) => {
        const [typeValue, setTypeValue] = useState<string>(type)

        const changeInputTypeHandler = () => {
            typeValue === 'text'
                ? setTypeValue('password')
                : setTypeValue('text')
        }

        // const focus = () => {
        //     ref?.current?.focus()
        // }

        return (
            <div>
                {label && (
                    <label
                        htmlFor={id}
                        className="block flex justify-between font-medium text-black text-sm mb-2"
                    >
                        <span>{label}</span>
                        {type === 'password' && action === 'create' && (
                            <span>
                                <a
                                    href="/password-reset"
                                    className="text-primary"
                                >
                                    Forgot password?
                                </a>
                            </span>
                        )}
                    </label>
                )}
                <div className="relative inline-block w-full">
                    <input
                        ref={ref}
                        type={typeValue}
                        onChange={onChange}
                        onBlur={onBlur}
                        placeholder={placeholder}
                        id={id}
                        value={value}
                        autoComplete={autocomplete}
                        disabled={disabled}
                        onKeyDown={onKeyDown}
                        className={`${className} border placeholder:text-sm focus:border-primary !outline-none focus:outline-none text-sm h-10 block min-w-full py-2.5 px-4 ${
                            onError ? 'border-error-2' : 'border-gray-4'
                        } ${
                            disabled
                                ? 'bg-backgroun-3 text-gray-1 placeholder:text-gray-1'
                                : 'bg-white placeholder:text-dark-2 text-black'
                        } ${iconPosition === 'left' ? 'pl-10' : ''}`}
                    />
                    {icon && (
                        <span
                            onClick={() => changeInputTypeHandler()}
                            className={`absolute top-3 ${
                                iconPosition && iconPosition === 'left'
                                    ? 'left-4'
                                    : 'right-4'
                            } cursor-pointer
                            ${iconClassName}`}
                        >
                            {icon}
                        </span>
                    )}
                    {onError && (
                        <div className={'text-error-4 text-xs mt-0.5'}>
                            {onError}
                        </div>
                    )}
                </div>
            </div>
        )
    }
)
