// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iXePUn7EXQvUmQyL3WX7 {\n    box-shadow: 16px 32px 36px -8px rgba(10, 22, 39, 0.08);\n}\n\n.WJVfnUVO3cz3FtxoL2cA {\n    box-shadow: 16px 32px 36px -8px rgba(10, 22, 39, 0.08);\n}\n\n.yWN8PBKHeGXq4vwFn7QV {\n    box-shadow: 16px 32px 36px -8px rgba(10, 22, 39, 0.08);\n}\n\n.bVZQCm3md_rY6GX8d0nF {\n    box-shadow: 16px 32px 36px -8px rgba(10, 22, 39, 0.08);\n}", "",{"version":3,"sources":["webpack://./landingPage/components/section-cover/SectionCover.module.css"],"names":[],"mappings":"AAAA;IACI,sDAAsD;AAC1D;;AAEA;IACI,sDAAsD;AAC1D;;AAEA;IACI,sDAAsD;AAC1D;;AAEA;IACI,sDAAsD;AAC1D","sourcesContent":[".strength {\n    box-shadow: 16px 32px 36px -8px rgba(10, 22, 39, 0.08);\n}\n\n.analytics {\n    box-shadow: 16px 32px 36px -8px rgba(10, 22, 39, 0.08);\n}\n\n.profile {\n    box-shadow: 16px 32px 36px -8px rgba(10, 22, 39, 0.08);\n}\n\n.options {\n    box-shadow: 16px 32px 36px -8px rgba(10, 22, 39, 0.08);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"strength": "iXePUn7EXQvUmQyL3WX7",
	"analytics": "WJVfnUVO3cz3FtxoL2cA",
	"profile": "yWN8PBKHeGXq4vwFn7QV",
	"options": "bVZQCm3md_rY6GX8d0nF"
};
export default ___CSS_LOADER_EXPORT___;
