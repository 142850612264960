// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DlQgRFIuhA28DNIQwWye {\n    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.08);\n}", "",{"version":3,"sources":["webpack://./core/components/common/RefreshPageModal/RefreshPageModal.module.css"],"names":[],"mappings":"AAAA;IACI,4CAA4C;AAChD","sourcesContent":[".shadow {\n    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.08);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shadow": "DlQgRFIuhA28DNIQwWye"
};
export default ___CSS_LOADER_EXPORT___;
