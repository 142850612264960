import React from 'react'
import { Provider } from 'react-redux'
import { store } from '../core/app/store/store'
import { LandingPage } from './App'
import { createRoot } from 'react-dom/client'
import './styles.css'
import 'tw-elements'

const app = document.getElementById('root')
const root = createRoot(app!)
root.render(
    <Provider store={store}>
        <LandingPage />
    </Provider>
)
