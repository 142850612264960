import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ICountryCode, IEmail, IGotFromOtp, IInfo, IPhoneSMS, ISendOtp, ISendPhone, IUid } from './models/signup/models';

export const signupApi = createApi({
    reducerPath: 'signupApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.API_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = document.getElementsByName('csrf-token')[0]?.getAttribute('content')
            if (token) headers.set('X-CSRF-TOKEN', token)
            headers.set('content-type', 'application/json')
            return headers
        },
        credentials: process.env.NODE_ENV === 'development' ? 'include' : 'same-origin'
    }),
    endpoints: (builder) => ({
        setEmail: builder.mutation<IUid, IEmail>({
            query: (body) => ({
                url: '/register/via/email',
                method: 'POST',
                body,
            })
        }),
        setInfo: builder.mutation<string, IInfo>({
            query: (body) => ({
                url: '/register/step/info',
                method: 'POST',
                body
            })
        }),
        getCountryNumbers: builder.query<ICountryCode, any>({
            query: (code: number) => ({
                url: `/country/phone-prefix?prefix=${code}`,
                method: 'GET'
            })
        }),
        sendMobilePhone: builder.mutation<IPhoneSMS, ISendPhone>({
            query: (body) => ({
                url: '/register/step/mobile-phone',
                method: 'POST',
                body
            })
        }),
        resendCode: builder.mutation<IPhoneSMS, IUid>({
            query: (body) => ({
                url: '/register/step/mobile-phone/otp/resend',
                method: 'POST',
                body
            })
        }),
        sendOtp: builder.mutation<IGotFromOtp, ISendOtp>({
            query: (body) => ({
                url: '/register/step/mobile-phone/otp/confirmation',
                method: 'POST',
                body
            })
        })
    }),
});

export const {useSetEmailMutation, useSetInfoMutation, useGetCountryNumbersQuery, useSendMobilePhoneMutation, useResendCodeMutation, useSendOtpMutation} = signupApi;
