import { FC, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks/hooks'
import { logged, setLogged } from '../../app/slices/signup'
import logo from '../../assets/images/logo.svg'
import { HeaderNav } from './HeaderNav/HeaderNav'
import { HeaderTools } from './HeaderTools/HeaderTools'

export const Header: FC = () => {
    const dispatch = useAppDispatch()
    const isLogged = useAppSelector(logged)

    useEffect(() => {
        const logged = localStorage.getItem('logged')
        dispatch(setLogged(logged === 'true' ? true : false))
    }, [])

    return (
        <header
            id={'main-header'}
            className="w-full sticky md:static top-0 z-[52] bg-white h-[52px] md:h-[72px] flex items-center justify-center px-4 xl:px-0"
        >
            <div className="container flex justify-between items-center">
                <a
                    href={process.env.API_URL}
                    className={
                        'max-w-[96px] h-[32px] md:h-auto md:max-w-[auto]'
                    }
                >
                    <img src={logo} alt="logo" />
                </a>
                <HeaderNav />
                <HeaderTools />
            </div>
        </header>
    )
}
