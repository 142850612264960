import { FC, ReactNode, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks/hooks'
import { useWindowSize } from '../../../app/hooks/useDimensions'
import { isMobile, setNewDimension } from '../../../app/slices/profile'
import { Footer } from '../../Footer/Footer'
import { Header } from '../../Header/Header'
import { MobileStickyFooter } from '../../MobileStickyFooter/MobileStickyFooter'
import { RefreshPageModal } from '../RefreshPageModal/RefreshPageModal'

export const Layout: FC<{ content: ReactNode }> = ({ content }) => {
    const dispatch = useAppDispatch()
    const [width, height] = useWindowSize()
    const isMobileVersion = useAppSelector(isMobile)
    useEffect(() => {
        dispatch(setNewDimension(width))
    }, [width])
    return (
        <>
            <RefreshPageModal />
            <Header />
            {content}
            <Footer />
            <MobileStickyFooter />
        </>
    )
}
