import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IFilter, IPopularIndustries, IPopularLocations, IPostQuery, ISearchOptions, ISuggested } from './models/availableJobs/availableJobs';
import { IJobPost, ISimilarJob } from './models/jobPost/model';

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.API_URL,
    prepareHeaders: (headers, { getState }) => {
        const token = document.getElementsByName('csrf-token')[0]?.getAttribute('content')
        if (token) headers.set('X-CSRF-TOKEN', token)
        headers.set('content-type', 'application/json')

        return headers
    },
    credentials: process.env.NODE_ENV === 'development' ? 'include' : 'same-origin',
})

const baseQueryErrHandler = async (
    args: string | FetchArgs,
    api: BaseQueryApi,
    extraOptions: {}
) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error?.status === 401) {
        localStorage.setItem('logged', 'false')
    }
    if (result.meta?.request.method === 'POST' || result.meta?.request.method === 'DELETE' || result.meta?.request.method === 'UPDATE') {
        localStorage.setItem('csrf-token-time', '7200')
    }
    
    return result
}

export const availableJobsApi = createApi({
    reducerPath: 'availableJobsApi',
    baseQuery: baseQueryErrHandler,
    tagTypes: ['JobPost'],
    endpoints: (builder) => ({
        getDefaultFilters: builder.query<IFilter, any>({
            query: ({suggested}) => ({
                url: `/available-jobs/filters?${suggested}`,
                method: 'GET'
            })
        }),
        updateFilters: builder.query<IFilter, any>({
            query: ({filterString}) => ({
                url: `/available-jobs/filters/count?${filterString}`,
                method: 'GET'
            })
        }),
        getPosts: builder.query<IPostQuery, {params: string}>({
            query: ({params}) => ({
                url: `/available-jobs/items${params}`,
                method: 'GET'
            }),
            providesTags: ['JobPost']
        }),
        authCheck: builder.query<any, any>({
            query: () => ({
                url: `/auth-check`,
                method: 'GET',
            }),
            transformResponse: (data, error) => {
                return {
                    status: error?.response?.status === 200 ? true : false
                }
            }
        }),
        saveJob: builder.mutation<any, {job_post_id: number}>({
            query: (body) => ({
                url: '/saved-jobs',
                method: 'POST',
                body
            }),
            invalidatesTags: ['JobPost']
        }),
        removeSavedJob: builder.mutation<any, {job_post_id: number}>({
            query: (body) => ({
                url: '/saved-jobs',
                method: 'DELETE',
                body
            }),
            invalidatesTags: ['JobPost']
        }),
        getSavedJobs: builder.query<IPostQuery, any>({
            query: ({page, per_page}) => ({
                url: `/saved-jobs/items${page && `?page=${page}`}`,
                method: 'GET',
            }),
            providesTags: ['JobPost']
        }),
        getJobPostInfo: builder.query<IJobPost, any>({
            query: ({id}) => ({
                url: `/available-jobs/item/${id}`,
                method: 'GET'
            }),
            providesTags: ['JobPost']
        }),
        getSimilarJobs: builder.query<ISimilarJob, any>({
            query: ({id}) => ({
                url: `/available-jobs/item/${id}/similar`,
                method: 'GET'
            }),
            providesTags: ['JobPost']
        }),
        getApplyUrl: builder.query<{redirect_to: string}, any>({
            query: ({id}) => ({
                url: `/available-jobs/item/${id}/apply-url`,
                method: 'GET'
            })
        }),
        getSearchOptions: builder.query<ISearchOptions, any>({
            query: () => ({
                url: `/landing-page/search-options`,
                method: 'GET'
            })
        }),
        getPopularIndustries: builder.query<IPopularIndustries, any>({
            query: () => ({
                url: '/landing-page/popular-industries',
                method: 'GET'
            })
        }),
        getPopularLocations: builder.query<IPopularLocations, any>({
            query: () => ({
                url: 'landing-page/popular-locations',
                method: 'GET'
            })
        }),
        getSuggestedJobs: builder.query<ISuggested, any>({
            query: () => ({
                url: '/profile/suggested-jobs',
                method: 'GET'
            })
        })
    }),
});


export const { useLazyGetDefaultFiltersQuery, useGetDefaultFiltersQuery, useUpdateFiltersQuery, useLazyUpdateFiltersQuery, useGetPostsQuery, useAuthCheckQuery, useSaveJobMutation, useRemoveSavedJobMutation, useGetJobPostInfoQuery, useGetSimilarJobsQuery, useGetSavedJobsQuery, useGetApplyUrlQuery, useLazyGetApplyUrlQuery, useGetSearchOptionsQuery, useGetPopularIndustriesQuery, useGetPopularLocationsQuery, useLazyGetSuggestedJobsQuery } = availableJobsApi

