import { m, motion, Variants } from 'framer-motion'
import { FC } from 'react'
import { Button } from '../../../core/components/common/Button/Button'
import LogoRandstat from '../../assets/images/logo-circle-randstat.svg'
import LogoAcapedia from '../../assets/images/logo-circle-acapedia.svg'
import LogoDeloitte from '../../assets/images/logo-circle-deloitte.svg'
import LogoOnepartnership from '../../assets/images/logo-circle-onepartnership.svg'
import recruiter1Image from '../../assets/images/recruiter-1.svg'
import recruiter2Image from '../../assets/images/recruiter-2.svg'
import recruiter3Image from '../../assets/images/recruiter-3.svg'

export const SectionProfile: FC = () => {
    return (
        <div className="container mx-auto flex items-center justify-between flex-col-reverse md:flex-row">
            <div className="relative w-[250px] h-[250px] mt-[60px] md:-ml-[35px] md:mt-0 md:w-[380px] md:h-[380px] lg:w-[528px] lg:h-[528px] xl:ml-0">
                <motion.div
                    initial={'rest'}
                    whileHover={'hover'}
                    animate={'rest'}
                    className={
                        'w-full h-full md:scale-[0.65] lg:scale-[0.71] xl:scale-100'
                    }
                >
                    <motion.div
                        variants={Circle1Motion}
                        className="absolute left-0 bg-[#F8CC79] rounded-full w-[250px] h-[250px] md:w-[380px] md:h-[380px] lg:w-[528px] lg:h-[528px]"
                    />
                    <motion.div
                        variants={Circle2Motion}
                        className="absolute left-0 bg-[#F8CC79] opacity-50 rounded-full w-[250px] h-[250px] md:w-[380px] md:h-[380px] lg:w-[528px] lg:h-[528px]"
                    />
                    <motion.div
                        variants={Circle3Motion}
                        className="absolute left-0 bg-[#F8CC79] opacity-20 rounded-full w-[250px] h-[250px] md:w-[380px] md:h-[380px] lg:w-[528px] lg:h-[528px]"
                    />
                    <motion.div
                        variants={logoRandstatMotion}
                        className={
                            'absolute left-0 top-0 w-[80px] md:w-[130px] lg:w-auto'
                        }
                    >
                        <img src={LogoRandstat} alt="logo randstat" />
                    </motion.div>
                    <motion.div
                        variants={logoAcapediaMotion}
                        className={
                            'absolute left-0 bottom-0 w-[70px] md:w-[100px] lg:w-auto'
                        }
                    >
                        <img src={LogoAcapedia} alt="logo acapedia" />
                    </motion.div>
                    <motion.div
                        variants={onepartnershipMotion}
                        className={
                            'absolute -right-[25px] -bottom-[25px] md:right-0 md:bottom-0 w-[60px] md:w-[80px] lg:w-auto'
                        }
                    >
                        <img
                            src={LogoOnepartnership}
                            alt="logo onepartnerhisgroup"
                        />
                    </motion.div>
                    <motion.div
                        variants={recruiter1Motion}
                        className={
                            'absolute w-[140px] -left-[60px] -top-[60px] md:-left-[25px] md:-top-[25px] lg:left-0 lg:top-0 md:w-[190px] lg:w-auto'
                        }
                    >
                        <img src={recruiter1Image} alt="logo recruiter" />
                    </motion.div>
                    <motion.div
                        variants={recruiter2Motion}
                        className={
                            'absolute w-[140px] -left-[60px] -top-[90px] md:-left-[25px] md:-top-[35px] lg:left-0 lg:top-0 md:w-[190px] lg:w-auto'
                        }
                    >
                        <img src={recruiter2Image} alt="logo recruiter" />
                    </motion.div>
                    <motion.div
                        variants={recruiter3Motion}
                        className={
                            'absolute w-[140px] -left-[60px] -top-[120px] md:-left-[25px] md:-top-[45px] lg:left-0 lg:top-0 md:w-[190px] lg:w-auto'
                        }
                    >
                        <img src={recruiter3Image} alt="logo recruiter" />
                    </motion.div>
                    <motion.div
                        variants={logoDeloitteMotion}
                        className={
                            'absolute right-0 top-0 w-[80px] md:w-[130px] lg:w-auto'
                        }
                    >
                        <img src={LogoDeloitte} alt="logo deloitte" />
                    </motion.div>
                    <motion.div
                        variants={circleMediumMotion}
                        className={
                            'absolute w-[24px] h-[24px] -left-[55px] bottom-[10px] md:-left-[25px] md:bottom-[10px] lg:left-0 lg:bottom-0 md:w-[36px] md:h-[36px] lg:w-[54px] lg:h-[54px] bg-[#5370D8] rounded-full'
                        }
                    ></motion.div>
                    <motion.div
                        variants={circleSmallMotion}
                        className={
                            'absolute w-[16px] h-[16px] -right-[10px] md:-right-[5px] lg:right-0 lg:top-0 md:w-[24px] md:h-[24px] lg:w-[36px] lg:h-[36px] bg-[#5370D8] rounded-full'
                        }
                    ></motion.div>
                </motion.div>
            </div>
            <div className="md:max-w-[320px] lg:w-[400px] xl:w-[480px]">
                <h2 className="text-center text-[32px] md:text-left lg:text-[40px] font-semibold">
                    Create Profile
                </h2>
                <p className="mt-4 text-center md:text-left lg:mt-8 text-lg">
                    Want to know more about the companies? Many of the leading
                    nordic recruitment and staffing agencies have a profile in
                    jobreg.no.
                </p>
                <div className="w-[200px] mt-8 mx-auto md:mr-auto md:ml-0 lg:mt-[48px]">
                    <Button
                        type={'button'}
                        view={'primary'}
                        title={'Create Profile'}
                        onClick={() =>
                            (location.href = `${process.env.API_URL}/registration`)
                        }
                    />
                </div>
            </div>
        </div>
    )
}

const Circle1Motion = {
    rest: {
        x: 0,
        y: 0,
    },
    hover: {
        x: 0,
        y: 0,
        transition: {
            duration: 0.5,
        },
    },
}

const Circle2Motion = {
    rest: {
        x: 0,
        y: 0,
    },
    hover: {
        x: -56,
        y: 16,
        transition: {
            duration: 0.5,
        },
    },
}

const Circle3Motion = {
    rest: {
        x: 0,
        y: 0,
    },
    hover: {
        x: 56,
        y: -40,
        transition: {
            duration: 0.5,
        },
    },
}

const circleMediumMotion = {
    rest: {
        x: 83,
        y: -49,
    },
    hover: {
        x: 62,
        y: -10,
        transition: {
            duration: 0.5,
        },
    },
}

const circleSmallMotion = {
    rest: {
        x: 15,
        y: 58,
    },
    hover: {
        x: 44,
        y: -40,
        transition: {
            duration: 0.5,
        },
    },
}
const logoRandstatMotion = {
    rest: {
        y: 0,
        x: 23,
    },
    hover: {
        x: -38,
        y: -26,
        transition: {
            duration: 0.5,
        },
    },
}
const logoAcapediaMotion = {
    rest: {
        y: -64,
        x: -19,
    },
    hover: {
        x: -48,
        y: -31,
        transition: {
            duration: 0.5,
        },
    },
}
const recruiter1Motion = {
    rest: {
        x: 122,
        y: 125,
        rotate: [0, 5],
    },
    hover: {
        x: 136,
        y: 28,
        rotate: [0, 0],
        transition: {
            duration: 0.5,
        },
    },
}
const recruiter2Motion = {
    rest: {
        x: 122,
        y: 178,
        rotate: [0, -5],
    },
    hover: {
        x: 96,
        y: 182,
        rotate: [0, 0],
        transition: {
            duration: 0.5,
        },
    },
}

const recruiter3Motion = {
    rest: {
        x: 125,
        y: 239,
        rotate: [0, 5],
    },
    hover: {
        x: 152,
        y: 335,
        rotate: [0, 0],
        transition: {
            duration: 0.5,
        },
    },
}
const logoDeloitteMotion = {
    rest: {
        y: 55,
        x: 27,
    },
    hover: {
        x: 44,
        y: -42,
        transition: {
            duration: 0.5,
        },
    },
}
const onepartnershipMotion = {
    rest: {
        x: -58,
        y: -7,
    },
    hover: {
        x: 12,
        y: 25,
        transition: {
            duration: 0.5,
        },
    },
}
