import { FC, useEffect, useRef, useState } from 'react'
import IconDanger from '../../../assets/icons/IconDanger.svg'
import { Button } from '../Button/Button'
import styles from './RefreshPageModal.module.css'
import { useOnClickOutside } from '../../../app/hooks/useOnClickOutside'
import { TOKEN_TIME } from '../../../app/utils/constants.js'

export const RefreshPageModal: FC = () => {
    const [opened, setOpened] = useState<boolean | null>(false)
    const [tokenTime, setTokenTime] = useState<number>(TOKEN_TIME)

    useEffect(() => {
        if (tokenTime === 0) {
            setOpened(true)
            return
        }

        const intervalId = setInterval(() => {
            setTokenTime(tokenTime - 1)
        }, 1000)

        return () => clearInterval(intervalId)
    }, [tokenTime])

    useEffect(() => {
        localStorage.setItem('csrf-token-time', `${tokenTime}`)
    }, [])

    useEffect(() => {
        const time = localStorage.getItem('csrf-token-time')
        time && setTokenTime(+time)
    }, [localStorage.getItem('csrf-token-time')])

    const container = useRef<HTMLDivElement>(null)

    const clickOutsideHandler = () => {
        setOpened(false)
    }

    useOnClickOutside(container, clickOutsideHandler)

    const refresh = () => {
        location.reload()
    }

    return (
        <>
            {opened && (
                <div
                    ref={container}
                    className={`fixed top-[80px] left-[50%] -translate-x-[50%] z-[999] bg-white py-3 px-4 flex gap-2 items-center border border-error-2 rounded ${styles.shadow}`}
                >
                    <div className="w-22 h-22">
                        <img src={IconDanger} alt="icon danger" />
                    </div>
                    <p className="text-sm font-medium text-dark-4">
                        You have been inactive too long. Please refresh page.
                    </p>
                    <div className="w-[128px]">
                        <Button
                            type={'button'}
                            view={'secondary'}
                            title={'Refresh Page'}
                            onClick={() => refresh()}
                        />
                    </div>
                </div>
            )}
        </>
    )
}
