import { FC } from 'react'
import { ISelectItem } from './SelectItem.model'

export const SelectItem: FC<ISelectItem> = ({
    title,
    onClick,
    icon,
    className,
    iconClassName,
}) => {
    return (
        <li
            className={`flex items-center py-3.5 px-3 cursor-pointer ${className}`}
            onClick={onClick}
        >
            {icon && (
                <span className={`mr-2 ${iconClassName}`}>
                    <img src={icon} alt={`icon-${title}`} />
                </span>
            )}
            <span className="font-semibold text-sm mr-2">{title}</span>
        </li>
    )
}
