import { motion, useTransform } from 'framer-motion'
import { FC } from 'react'
import { Button } from '../../../core/components/common/Button/Button'
import post1Image from '../../assets/images/post_1.svg'
import post2Image from '../../assets/images/post_2.svg'
import post3Image from '../../assets/images/post_3.svg'
import post4Image from '../../assets/images/post_4.svg'

export const SectionJobs: FC = () => {
    return (
        <div className="container mx-auto flex items-center justify-between flex-col-reverse md:flex-row">
            <div className="relative w-[250px] h-[250px] mt-[60px] md:mt-0 md:w-[380px] md:h-[380px] lg:w-[528px] lg:h-[528px] md:-ml-[50px]">
                <motion.div
                    initial={'rest'}
                    whileHover={'hover'}
                    animate={'rest'}
                    className={
                        'w-full h-full md:scale-[0.65] lg:scale-[0.71] xl:scale-100'
                    }
                >
                    <motion.div
                        variants={Circle1Motion}
                        className="absolute left-0 bg-[#5370D8] rounded-full w-[250px] h-[250px] md:w-[380px] md:h-[380px] lg:w-[528px] lg:h-[528px]"
                    />
                    <motion.div
                        variants={Circle2Motion}
                        className="absolute left-0 bg-[#5370D8] opacity-50 rounded-full w-[250px] h-[250px] md:w-[380px] md:h-[380px] lg:w-[528px] lg:h-[528px]"
                    />
                    <motion.div
                        variants={Circle3Motion}
                        className="absolute left-0 bg-[#5370D8] opacity-20 rounded-full w-[250px] h-[250px] md:w-[380px] md:h-[380px] lg:w-[528px] lg:h-[528px]"
                    />
                    <motion.div
                        variants={post1}
                        className="absolute top-[10px] md:-top-[5px] left-[35px] w-[180px] md:left-[50px] md:w-[300px] lg:top-[10px] lg:w-auto"
                    >
                        <img src={post1Image} />
                    </motion.div>
                    <motion.div
                        variants={post2}
                        className="absolute top-[65px] left-[30px] w-[180px] md:top-[80px] md:left-[30px] md:w-[300px] lg:top-[130px] lg:w-auto"
                    >
                        <img src={post2Image} />
                    </motion.div>
                    <motion.div
                        variants={post3}
                        className="absolute top-[115px] left-[45px] w-[180px] md:top-[160px] md:left-[70px] md:w-[300px] lg:top-[250px] lg:w-auto"
                    >
                        <img src={post3Image} />
                    </motion.div>
                    <motion.div
                        variants={post4}
                        className="absolute top-[165px] left-[40px] w-[180px] md:top-[240px] md:left-[60px] md:w-[300px] lg:top-[350px] lg:w-auto"
                    >
                        <img src={post4Image} />
                    </motion.div>
                </motion.div>
            </div>
            <div className="md:w-[320px] lg:w-[400px] xl:w-[480px]">
                <h2 className="font-semibold text-center text-[32px] md:text-left lg:text-[40px]">
                    Thousands of Jobs
                </h2>
                <p className="text-center mt-4 md:text-left md:text-base lg:text-lg lg:mt-8">
                    Jobreg has at any given time several thousands jobs
                    available within a number of industries. Here you find both
                    full and part time, as well as temp jobs. You find jobs from
                    both companies, recruitment- and staffing agencies in one
                    place.
                </p>
                <div className="w-[200px] mt-8 mx-auto md:mr-auto md:ml-0 lg:mt-[48px]">
                    <Button
                        type={'button'}
                        view={'primary'}
                        title={'View all jobs'}
                        onClick={() =>
                            (location.href = `${process.env.API_URL}/available-jobs`)
                        }
                    />
                </div>
            </div>
        </div>
    )
}

const Circle1Motion = {
    rest: {
        x: 0,
        y: 0,
    },
    hover: {
        x: -50,
        y: -50,
        transition: {
            duration: 0.5,
        },
    },
}

const Circle2Motion = {
    rest: {
        x: 0,
        y: 0,
    },
    hover: {
        x: -50,
        y: 50,
        transition: {
            duration: 0.5,
        },
    },
}

const Circle3Motion = {
    rest: {
        x: 0,
        y: 0,
    },
    hover: {
        x: 75,
        y: 0,
        transition: {
            duration: 0.5,
        },
    },
}

const post1 = {
    rest: {
        x: 0,
        y: 0,
    },
    hover: {
        x: -50,
        y: 0,
        rotate: [0, -35],
        transition: {
            duration: 0.5,
        },
    },
}

const post2 = {
    rest: {
        x: 0,
        y: 0,
    },
    hover: {
        x: 25,
        y: -10,
        rotate: [0, -25],
        transition: {
            duration: 0.5,
        },
    },
}

const post3 = {
    rest: {
        x: 0,
        y: 0,
    },
    hover: {
        x: 10,
        y: -15,
        rotate: [0, -10],
        transition: {
            duration: 0.5,
        },
    },
}

const post4 = {
    rest: {
        x: 0,
        y: 0,
    },
    hover: {
        x: 25,
        y: 25,
        transition: {
            duration: 0.5,
        },
    },
}
