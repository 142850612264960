import { FC, useRef } from 'react'
import { Button } from '../../../core/components/common/Button/Button'
import IconArrowBack from '../../../core/assets/icons/IconArrowBack.svg'
import styles from './SectionLocations.module.css'
import { useGetPopularLocationsQuery } from '../../../core/app/api/availableJobs'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination } from 'swiper'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'

SwiperCore.use([Navigation])

const CustomSlide = (props: {
    id: number
    name: any
    count: number
    flag: string
    countryId: number
}) => {
    const { name, id, count, flag, countryId } = props
    const chooseLocationHandler = (countryId: number, regionId: number) => {
        const url = new URL(`${process.env.API_URL}/available-jobs`)
        url.searchParams.append('country', countryId.toString())
        url.searchParams.append('region', regionId.toString())
        location.href = url.toString()
    }
    return (
        <div
            className={`${styles.card} px-2 xl:mx-0 xl:w-[280px] h-[344px]`}
            key={id}
        >
            <div className={styles.inner} />
            <div
                className={`${styles.content} p-6 bg-white flex flex-col text-center`}
            >
                <div className="relative mx-auto w-[88px] h-[88px] mt-4">
                    <div className="absolute -bottom-[10px] -left-[15px] w-[42px] h-[42px] bg-[#529CFF] rounded-full"></div>
                    <img
                        src={flag}
                        alt="flag logo"
                        className={'absolute w-full h-full'}
                    />
                    <div className="absolute -top-[10px] -right-[10px] w-[15px] h-[15px] bg-[#529CFF] rounded-full" />
                </div>
                <h3 className="mt-6 text-xl font-semibold text-center">
                    {name}
                </h3>
                <span className="mt-2 text-sm text-dark-4">{count} jobs</span>
                <div className="mt-auto">
                    <Button
                        type={'button'}
                        view={'additional'}
                        title={'View Jobs'}
                        onClick={() => chooseLocationHandler(countryId, id)}
                        className={'text-sm !hover:bg-primary'}
                    />
                </div>
            </div>
        </div>
    )
}

export const SectionLocations: FC = () => {
    const { data: popularLocations } = useGetPopularLocationsQuery({})

    const chooseLocationHandler = (countryId: number, regionId: number) => {
        const url = new URL(`${process.env.API_URL}/available-jobs`)
        url.searchParams.append('country', countryId.toString())
        url.searchParams.append('region', regionId.toString())
        location.href = url.toString()
    }

    return (
        <div className="container mx-auto">
            <div className="flex items-end md:items-center justify-between mb-8 md:mb-[48px]">
                <h2 className="text-[32px] leading-[44px] md:text-[40px] md:leading-[60px] font-semibold">
                    Popular Locations
                </h2>
                <div className="xl:hidden min-w-[112px] w-[112px] h-[48px] relative">
                    <span
                        className={`w-12 h-12 rounded-full bg-white select-none absolute top-[50%] -translate-y-[50%] left-0 z-50 flex items-center justify-center cursor-pointer prev-el`}
                    >
                        <img src={IconArrowBack} />
                    </span>
                    <span
                        className={`w-12 h-12 rounded-full bg-white select-none absolute top-[50%] -translate-y-[50%] right-0 z-50 flex items-center justify-center cursor-pointer next-el`}
                    >
                        <img src={IconArrowBack} className={'rotate-180'} />
                    </span>
                </div>
                <div className="hidden xl:block w-[160px]">
                    <Button
                        type={'button'}
                        view={'additional'}
                        title={'See all jobs'}
                        onClick={() =>
                            (location.href = `${process.env.API_URL}/available-jobs`)
                        }
                    />
                </div>
            </div>
            <div className="xl:hidden overflow-y-hidden mt-4 -mr-[25px] md:mt-0 md:mr-0 h-[340px]">
                <Swiper
                    navigation={{
                        nextEl: '.next-el',
                        prevEl: '.prev-el',
                    }}
                    slidesPerView={1.5}
                    spaceBetween={20}
                    loop={true}
                    pagination={{
                        clickable: true,
                    }}
                    className="mySwiper"
                    modules={[Navigation]}
                    breakpoints={{
                        640: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                        },
                    }}
                >
                    {popularLocations &&
                        popularLocations.popular_locations.map((location) => (
                            <SwiperSlide>
                                <CustomSlide
                                    key={location.region_id}
                                    id={location.region_id}
                                    countryId={location.country_id}
                                    name={location.name}
                                    count={location.count}
                                    flag={location.flag}
                                />
                            </SwiperSlide>
                        ))}
                </Swiper>
            </div>
            {popularLocations && (
                <div className="hidden xl:flex justify-between">
                    {popularLocations.popular_locations.map((location) => (
                        <CustomSlide
                            key={location.region_id}
                            id={location.region_id}
                            name={location.name}
                            count={location.count}
                            flag={location.flag}
                            countryId={location.country_id}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}
