import { FC, useEffect, useState } from 'react'

export const HeaderNav: FC = () => {
    const [url, setUrl] = useState<string>('')

    useEffect(() => {
        const currentUrl = new URL(location.href).pathname
        setUrl(currentUrl.toString())
    }, [])

    return (
        <ul className="header-nav hidden md:flex items-center gap-2 lg:gap-4">
            <li
                className={`${
                    url === '/' ? 'bg-background-2' : ''
                } rounded-[128px] h-full flex items-center text-sm leading-5 font-semibold text-dark-5 cursor-pointer transition-all hover:bg-background-2`}
            >
                <a href="/" className="px-4 py-2 w-full h-full">
                    Home
                </a>
            </li>
            <li
                className={`${
                    url === '/available-jobs' ? 'bg-background-2' : ''
                } rounded-[128px] h-full flex items-center text-sm font-semibold text-dark-5 cursor-pointer transition-all hover:bg-background-2`}
            >
                <a href="/available-jobs" className="px-4 py-2 w-full h-full">
                    Available jobs
                </a>
            </li>
            <li
                className={`${
                    url === '/contact' ? 'bg-background-2' : ''
                } rounded-[128px] h-full flex items-center text-sm font-semibold text-dark-5 cursor-pointer transition-all hover:bg-background-2`}
            >
                <a href="/contact" className="px-4 py-2 w-full h-full">
                    Contact
                </a>
            </li>
        </ul>
    )
}
