import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IMetaSMS } from '../api/models/signup/models'
import { RootState } from '../store/store'

export interface ISignUpSlice {
    steps: string[]
    currentStep: string
    uid: string
    meta: IMetaSMS
    phone: string
    phonePrefix: string
    logged: boolean
    email: string
    fName: string
    lName: string
}

const initialState: ISignUpSlice = {
    steps: ['email', 'info', 'mobile_phone', 'verification'],
    currentStep: 'email',
    uid: '',
    phone: '',
    phonePrefix: '',
    meta: {},
    logged: false,
    email: '',
    fName: '',
    lName: '',
}

const signupSlice = createSlice({
    name: 'signup',
    initialState,
    reducers: {
        prevStep: (state) => {
            if (state.currentStep !== 'email') {
                const idx = state.steps.indexOf(state.currentStep)
                state.currentStep = state.steps[idx - 1]
            }
        },
        nextStep: (state, action: PayloadAction<string>) => {
            state.currentStep = action.payload
        },
        setUid: (state, action: PayloadAction<string>) => {
            state.uid = action.payload
        },
        setPhone: (state, action: PayloadAction<string>) => {
            state.phone = action.payload
        },
        setPhonePrefix: (state, action: PayloadAction<string>) => {
            state.phonePrefix = action.payload
        },
        getMetaForSMS: (state, action: PayloadAction<IMetaSMS>) => {
            state.meta = action.payload
        },
        setLogged: (state, action: PayloadAction<boolean>) => {
            state.logged = action.payload
            localStorage.setItem('logged', `${action.payload}`)
        },
        setEmailApp: (state, action: PayloadAction<string>) => {
            state.email = action.payload
        },
        setFName: (state, action: PayloadAction<string>) => {
            state.fName = action.payload
        },
        setLName: (state, action: PayloadAction<string>) => {
            state.lName = action.payload
        },
    },
})

export const {
    prevStep,
    setUid,
    nextStep,
    getMetaForSMS,
    setPhone,
    setPhonePrefix,
    setLogged,
    setEmailApp,
    setFName,
    setLName,
} = signupSlice.actions
export const currentStep = (state: RootState) => state.signup.currentStep
export const uid = (state: RootState) => state.signup.uid
export const phone = (state: RootState) => state.signup.phone
export const phonePrefix = (state: RootState) => state.signup.phonePrefix
export const meta = (state: RootState) => state.signup.meta
export const email = (state: RootState) => state.signup.email
export const fName = (state: RootState) => state.signup.fName
export const lName = (state: RootState) => state.signup.lName
export const logged = (state: RootState) => state.signup.logged
export default signupSlice.reducer
