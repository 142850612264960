import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IPasswordReset, IPasswordResetResponse, IResetMeta, IResetPasswordEmail, IResetPasswordOtp } from './models/forgotPassword/models';
import { IMetaSMS } from './models/signup/models';

export const forgotPasswordApi = createApi({
    reducerPath: 'forgotPasswordApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.API_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = document.getElementsByName('csrf-token')[0]?.getAttribute('content')
            if (token) headers.set('X-CSRF-TOKEN', token)
            headers.set('content-type', 'application/json')
            return headers
        }
    }),
    endpoints: (builder) => ({
        sendEmail: builder.mutation<IResetMeta, IResetPasswordEmail>({
            query: (body) => ({
                url: '/password-reset/via/email',
                method: 'POST',
                body
            })
        }),
        resendCode: builder.mutation<{meta: IMetaSMS}, {'password_reset_flow_uuid': string}>({
            query: (body) => ({
                url: '/password-reset/step/otp/resend',
                method: 'POST',
                body
            })
        }),
        sendOtp: builder.mutation<{'next_step': string}, IResetPasswordOtp>({
            query: (body) => ({
                url: '/password-reset/step/otp/confirmation',
                method: 'POST',
                body
            })
        }),
        resetPassword: builder.mutation<IPasswordResetResponse, IPasswordReset>({
            query: (body) => ({
                url: '/password-reset/step/reset',
                method: 'POST',
                body
            })
        })
    }),
});

export const { useSendEmailMutation, useResendCodeMutation, useSendOtpMutation, useResetPasswordMutation } = forgotPasswordApi

