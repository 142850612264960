const setLocation = (curLoc: string) => {
    try {
        history.pushState('string', '', curLoc)

        return
    } catch (e) {
        console.log('err')
    }
    location.hash = '#' + curLoc
}

export default setLocation
