import { FC } from 'react'
import { Button } from '../../../core/components/common/Button/Button'
import { motion } from 'framer-motion'
import ProfileInfoImage from '../../assets/images/profile_info.svg'
import ProfileStrengthImage from '../../assets/images/profile_strength.svg'
import ProfileAnalyticsImage from '../../assets/images/profile_analytics.svg'
import ProfileOptionsImage from '../../assets/images/profile_options.svg'
import ProfileJob1Image from '../../assets/images/profile_job_1.svg'
import ProfileJob2Image from '../../assets/images/profile_job_2.svg'
import ProfileJob3Image from '../../assets/images/profile_job_3.svg'
import logoRandstat from '../../assets/images/logo/logo-randstat.svg'
import logoDedicare from '../../assets/images/logo/logo-dedicare.svg'
import logoJob4u from '../../assets/images/logo/logo-job4u.svg'
import logoCentric from '../../assets/images/logo/logo-centric.svg'
import logoAcapedia from '../../assets/images/logo/logo-acapedia.svg'
import styles from './SectionCover.module.css'

export const SectionCover: FC = () => {
    return (
        <div className="container mx-auto">
            <div className="flex items-center justify-between flex-col md:flex-row">
                <div className="mb-[60px] md:mb-0 md:max-w-[320px] lg:max-w-[400px] xl:max-w-[488px]">
                    <h1
                        className={
                            'font-semibold text-center text-[40px] mb-4 md:mb-0 md:text-left md:leading-[60px] lg:text-[64px] lg:leading-[64px] lg:mb-6 md:mb-4'
                        }
                    >
                        Get
                        <br className="hidden lg:block" /> Headhunted
                    </h1>
                    <p
                        className={
                            'text-center text-base text-dark-7 mb-8 md:mb-0 md:text-left md:leading-7 lg:text-lg lg:leading-8 md:mb-[32px] lg:mb-[48px]'
                        }
                    >
                        Tired of applying for jobs? Jobreg gathers the leading
                        nordic recruitment and staffing agencies. Flexible
                        setting of job search in the personal profile.
                    </p>
                    <div className="btn max-w-[160px] mx-auto md:mr-auto md:ml-0">
                        <Button
                            type={'button'}
                            view={'primary'}
                            title={'Sign Up'}
                            onClick={() =>
                                (location.href = `${process.env.API_URL}/register`)
                            }
                        />
                    </div>
                </div>
                <div className="w-[250px] h-[250px] md:w-[380px] md:h-[380px] lg:w-[528px] lg:h-[528px] relative">
                    <motion.div
                        initial={'rest'}
                        whileHover={'hover'}
                        animate={'rest'}
                        className={
                            'w-full h-full scale-[0.95] md:scale-[0.65] lg:scale-[0.71] xl:scale-100'
                        }
                    >
                        <motion.div
                            variants={Circle1Motion}
                            className="absolute left-0 bg-[#79CAF8] rounded-full w-[250px] h-[250px] md:w-[380px] md:h-[380px] lg:w-[528px] lg:h-[528px]"
                        />
                        <motion.div
                            variants={Circle2Motion}
                            className="absolute left-0 bg-[#79CAF8] opacity-50 rounded-full w-[250px] h-[250px] md:w-[380px] md:h-[380px] lg:w-[528px] lg:h-[528px]"
                        />
                        <motion.div
                            variants={Circle3Motion}
                            className="absolute left-0 bg-[#79CAF8] opacity-20 rounded-full w-[250px] h-[250px] md:w-[380px] md:h-[380px] lg:w-[528px] lg:h-[528px]"
                        />
                        <motion.div
                            variants={profileStrengthImg}
                            className={`absolute w-[130px] h-auto -top-[25px] left-[30px] md:top-[25px] md:left-[25px] lg:top-0 lg:left-0 w-[250px] h-[250px] md:w-[180px] lg:w-auto ${styles.strength}`}
                        >
                            <img src={ProfileStrengthImage} />
                        </motion.div>
                        <motion.div
                            variants={profileAnalyticsImg}
                            className={`absolute w-[130px] -top-[170px] left-[15px] md:-top-[60px] md:left-[25px] md:w-[180px] lg:w-auto lg:top-0 lg:left-0 ${styles.analytics}`}
                        >
                            <img src={ProfileAnalyticsImage} />
                        </motion.div>
                        <motion.div
                            variants={profileInfoImg}
                            className={`absolute -top-[60px] left-0 md:top-0 ${styles.profile}`}
                        >
                            <img src={ProfileInfoImage} />
                        </motion.div>
                        <motion.div
                            variants={profileOptionsImg}
                            className={`absolute w-[130px] -top-[140px] right-[60px] md:right-[50px] md:-top-[40px] lg:top-0 lg:right-0 md:w-[180px] lg:w-auto lg:h-auto ${styles.options}`}
                        >
                            <img src={ProfileOptionsImage} />
                        </motion.div>
                        <motion.div
                            variants={profileJob3Img}
                            className="absolute w-[120px] top-[25px] right-[15px] md:w-[180px] lg:w-auto md:top-[60px] md:right-[15px] lg:top-[35px] lg:right-[25px]"
                        >
                            <img src={ProfileJob3Image} />
                        </motion.div>
                        <motion.div
                            variants={profileJob2Img}
                            className="absolute w-[120px] top-[25px] right-[15px] md:w-[180px] lg:w-auto md:top-[60px] md:right-[15px] lg:top-[35px] lg:right-[25px]"
                        >
                            <img src={ProfileJob2Image} />
                        </motion.div>
                        <motion.div
                            variants={profileJob1Img}
                            className="absolute w-[120px] top-[25px] right-[15px] md:w-[180px] md:top-[60px] md:right-[15px] lg:w-auto lg:top-[35px] lg:right-[25px]"
                        >
                            <img src={ProfileJob1Image} />
                        </motion.div>
                    </motion.div>
                </div>
            </div>
            <div className="mt-[80px] md:mt-[48px] lg:mt-0 xl:mt-[80px] flex items-center justify-between md:mr-[64px]">
                <div className="w-[150px] max-w-[150px] md:max-w-[112px] lg:max-w-[132px] xl:max-w-[224px]">
                    <img src={logoRandstat} alt="randstat logo" />
                </div>
                <div className="w-[150px] md:max-w-[112px] lg:max-w-[132px] xl:max-w-[224px]">
                    <img src={logoDedicare} alt="dedicare logo" />
                </div>
                <div className="hidden md:block md:max-w-[112px] lg:max-w-[132px] xl:max-w-[224px]">
                    <img src={logoJob4u} alt="job4u logo" />
                </div>
                <div className="hidden md:block md:max-w-[112px] lg:max-w-[132px] xl:max-w-[224px]">
                    <img src={logoCentric} alt="centric logo" />
                </div>
                <div className="hidden md:block md:max-w-[112px] lg:max-w-[132px] xl:max-w-[224px]">
                    <img src={logoAcapedia} alt="acapedia logo" />
                </div>
            </div>
        </div>
    )
}

const Circle1Motion = {
    rest: {
        x: 0,
        y: 0,
    },
    hover: {
        x: 50,
        y: 50,
        transition: {
            duration: 0.5,
        },
    },
}

const Circle2Motion = {
    rest: {
        x: 0,
    },
    hover: {
        x: -50,
        transition: {
            duration: 0.5,
        },
    },
}

const Circle3Motion = {
    rest: {
        x: 0,
        y: 0,
    },
    hover: {
        x: 25,
        y: -50,
        transition: {
            duration: 0.5,
        },
    },
}

const profileInfoImg = {
    rest: {
        x: 0,
        y: 125,
    },
    hover: {
        x: 0,
        y: 175,
        transition: {
            duration: 0.5,
        },
    },
}

const profileStrengthImg = {
    rest: {
        x: -70,
        y: 60,
    },
    hover: {
        x: 0,
        y: 25,
        transition: {
            duration: 0.5,
        },
    },
}

const profileAnalyticsImg = {
    rest: {
        x: -50,
        y: 345,
    },
    hover: {
        x: 0,
        y: 400,
        transition: {
            duration: 0.5,
        },
    },
}

const profileOptionsImg = {
    rest: {
        x: 100,
        y: 270,
    },
    hover: {
        x: 0,
        y: 400,
        transition: {
            duration: 0.5,
        },
    },
}

const profileJob1Img = {
    rest: {
        x: 0,
        y: 0,
    },
    hover: {
        x: 20,
        y: 75,
        transition: {
            duration: 0.5,
        },
    },
}

const profileJob2Img = {
    rest: {
        x: 0,
        y: 0,
    },
    hover: {
        x: 20,
        y: 0,
        transition: {
            duration: 0.5,
        },
    },
}

const profileJob3Img = {
    rest: {
        x: 0,
        y: 0,
    },
    hover: {
        x: 20,
        y: -75,
        transition: {
            duration: 0.5,
        },
    },
}
