import { FC, useEffect, useRef, useState, forwardRef } from 'react'
import { useOnClickOutside } from '../../../app/hooks/useOnClickOutside'
import { ISelect } from './Select.model'
import { SelectArrow } from './SelectArrow'
import { SelectItem } from './SelectItem'
import styles from './Select.module.css'
import AnimateHeight from 'react-animate-height'

export const Select: FC<ISelect> = forwardRef<HTMLDivElement, ISelect>(({
    data,
    selected,
    onChange,
    dropdownClassName,
    dropdownItemClassName,
    className,
    placeholder,
    label,
    disabled,
    reset,
    withIcon,
    onError,
    openedListClassName,
    iconClassName,
    onKeyDown,
},
ref
) => {
    const [opened, setOpened] = useState<boolean>(false)
    const [selectedOption, setSelectedOption] = useState<any>(selected)

    const container = useRef<HTMLDivElement>(null)

    const clickOutsideHandler = () => {
        setOpened(false)
    }

    useOnClickOutside(container, clickOutsideHandler)

    const openHandler = () => {
        let element = document.getElementById(label || '')

        element?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
        })

        setOpened(!opened)
    }
    
    const selectionHandler = (value: any) => {
        setSelectedOption(value)
        onChange(value)
        setOpened(!opened)
    }

    useEffect(() => {
        if (reset) setSelectedOption('')
    }, [reset])

    useEffect(() => {
        setSelectedOption(selected)
    }, [selected])

    
    return (
        <div ref={container} id={'label'}>
            {label && <div className="mb-2 font-medium text-sm">{label}</div>}
            <div  className="relative">
                <div ref={ref} 
                    onClick={() => openHandler()}
                    onKeyDown={onKeyDown}
                    className={`flex justify-between items-center px-4 py-2.5 text-sm cursor-pointer border rounded ${
                        opened
                            ? `border-primary ${openedListClassName}`
                            : 'border-gray-4'
                    }
                        ${onError ? 'border-error-2' : 'border-gray-4'}
                        ${
                            disabled
                                ? 'bg-background-3 text-gray-1 placeholder:text-gray-1 pointer-events-none'
                                : 'bg-white'
                        }
                        ${className}
                        `}
                >
                    <div className="flex items-center mr-1">
                        {withIcon && selectedOption && (
                            <span className="mr-2">
                                <img src={selectedOption.icon} />
                            </span>
                        )}
                        <span>
                            {typeof selectedOption === 'string'
                                ? selectedOption
                                : selectedOption?.title}
                        </span>
                        <span className="text-gray-1">
                            {(selectedOption === undefined ||
                                selectedOption === '' ||
                                selectedOption.title === '') &&
                                placeholder}
                        </span>
                    </div>
                    <SelectArrow position={opened} disabled={disabled} />
                </div>
                {onError && (
                    <span className={'text-error-4 text-xs mt-0.5'}>
                        {onError}
                    </span>
                )}
                <AnimateHeight
                    duration={300}
                    height={opened ? 'auto' : 0}
                    className={`${styles.selectList} absolute z-50  ${
                        dropdownClassName && dropdownClassName
                    }`}
                >
                    <div>
                        {data.map((item: any) => (
                            <SelectItem
                                key={item.title || item}
                                title={item.title || item}
                                icon={item.icon ? item.icon : null}
                                onClick={() => selectionHandler(item)}
                                className={dropdownItemClassName}
                                iconClassName={iconClassName}
                            />
                        ))}
                    </div>
                </AnimateHeight>
            </div>
        </div>
    )
}


);
const handleKeyDown = (e: KeyboardEvent) => {
    console.log('llega aqui');
    
    if (e.key === 'Tab') {
        // Allow tab key to propagate
        return;
    }
    
  
}