import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ITermsOfServiceData } from './models/policy/policy';

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.API_URL,
    prepareHeaders: (headers, { getState }) => {
        const token = document.getElementsByName('csrf-token')[0]?.getAttribute('content')
        if (token) headers.set('X-CSRF-TOKEN', token)
        headers.set('content-type', 'application/json')

        return headers
    },
    credentials: process.env.NODE_ENV === 'development' ? 'include' : 'same-origin',
})

const baseQueryErrHandler = async (
    args: string | FetchArgs,
    api: BaseQueryApi,
    extraOptions: {}
) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error?.status === 401) {
        localStorage.setItem('logged', 'false')
    }
    if (result.meta?.request.method === 'POST' || result.meta?.request.method === 'DELETE' || result.meta?.request.method === 'UPDATE') {
        localStorage.setItem('csrf-token-time', '7200')
    }
    
    return result
}

export const policyApi = createApi({
    reducerPath: 'policyApi',
    baseQuery: baseQueryErrHandler,
    endpoints: (builder) => ({
        getTermsOfServiceData: builder.query<ITermsOfServiceData, void>({
            query: () => ({
                url: '/policy/terms-of-service/data',
                method: 'GET',
            })
        }),
        setAcceptTermsOfService: builder.mutation<void, void>({
            query: () => ({
                url: '/policy/terms-of-service',
                method: 'POST',
            })
        })
    }),
});


export const { useGetTermsOfServiceDataQuery, useSetAcceptTermsOfServiceMutation } = policyApi

