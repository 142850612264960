import { skipToken } from '@reduxjs/toolkit/dist/query'
import { FC, useEffect, useState } from 'react'
import { useLogoutMutation } from '../../../app/api/loginApi'
import {
    useGetHeaderInfoQuery,
    useGetJobOptionsQuery,
    useGetRequiredProfileBlocksQuery,
    useSetAvailableForWorkMutation,
} from '../../../app/api/profileApi'
import { useAppDispatch, useAppSelector } from '../../../app/hooks/hooks'
import { logged, setLogged } from '../../../app/slices/signup'
import IconAvailabilityOn from '../../../assets/icons/IconAvailabilityOn.svg'
import IconAvailabilityOff from '../../../assets/icons/IconAvailabilityOff.svg'
import IconAvailabilityOnHover from '../../../assets/icons/IconAvailabilityOnHover.svg'
import IconAvailabilityOffHover from '../../../assets/icons/IconAvailabilityOffHover.svg'
import IconLang from '../../../assets/icons/IconLang.svg'
import IconLangHover from '../../../assets/icons/IconLangHover.svg'
import avatarUrl from '../../../assets/images/avatar.svg'
import { Button } from '../../common/Button/Button'
import { DropdownMenu } from '../../common/DropdownMenu/DropdownMenu'
import { Icon } from '../../common/Icon/Icon'
import { Switch } from '../../common/Switch/Switch'
import { RadioButtons } from '../RadioButtons/RadioButtons'
import {
    availableForJobNotify,
    isMobile,
    isTabletAir,
    setAvailableForWorkNotify,
    setProfileAvatar,
    setProfileName,
} from '../../../app/slices/profile'

export const HeaderTools: FC = () => {
    const dispatch = useAppDispatch()
    const isAuth = useAppSelector(logged)
    const showAvailableForWorkNotification = useAppSelector(
        availableForJobNotify
    )
    const { data: info, isLoading } = useGetHeaderInfoQuery(
        !isAuth ? skipToken : {}
    )
    const { data: available } = useGetJobOptionsQuery(!isAuth ? skipToken : {})
    const { data: requiredBlocks } = useGetRequiredProfileBlocksQuery(
        !isAuth ? skipToken : {}
    )
    const [setAvailableForWork] = useSetAvailableForWorkMutation()
    const [forWork, setForWork] = useState<boolean>(true)
    const [disableAvailableToWork, setDisableAvailableToWork] =
        useState<boolean>(false)
    const [logout] = useLogoutMutation()
    const [url, setUrl] = useState<string>('')
    const isTabletAirVersion = useAppSelector(isTabletAir)
    const isMobileVersion = useAppSelector(isMobile)

    const nameLength = isTabletAirVersion ? 15 : 40

    const handlerSwitchAvailable = async (value: boolean) => {
        try {
            await setAvailableForWork({
                status: value,
            }).unwrap()

            setForWork(value)
        } catch (error) {
            console.log(error)
        }
    }

    const handlerLogout = async () => {
        try {
            const response = await logout({}).unwrap()

            dispatch(setLogged(false))
            window.location.href = response.redirect_to
        } catch (error) {
            console.log(error)
        }
    }

    const formatFullName = (fullname: string) => {
        return fullname.length > nameLength
            ? `${fullname.slice(0, nameLength)}...`
            : fullname
    }

    useEffect(() => {
        if (available && requiredBlocks) {
            if (requiredBlocks.required_blocks.length !== 0) {
                setForWork(false)
                setDisableAvailableToWork(false)
            } else {
                setForWork(available.job_options.available_for_work)
                setDisableAvailableToWork(
                    available.job_options.can_turn_on_available_for_work
                )
            }
        }
    }, [available, requiredBlocks])

    useEffect(() => {
        const currentUrl = new URL(location.href).pathname
        setUrl(currentUrl.toString())

        if (!isAuth) setForWork(false)
    }, [])

    useEffect(() => {
        if (info) {
            dispatch(setProfileName(`${info.first_name} ${info.last_name}`))
            dispatch(setProfileAvatar(info.avatar))
        }
    }, [info])

    return (
        <div className="header-tools flex items-center justify-between">
            {/*<div
                className={`header-tools-available flex items-center min-w-[24px] mr-4 ${
                    info?.first_name && info?.last_name
                        ? 'block'
                        : 'hidden md:block'
                }`}
            >
                <DropdownMenu
                    parent={
                        <Icon
                            normal={
                                available?.job_options.available_for_work ||
                                !isAuth ? (
                                    <div className="min-w-[40px] h-[40px] flex items-center justify-center">
                                        <img src={IconAvailabilityOn} />
                                    </div>
                                ) : (
                                    <div className="min-w-[40px] h-[40px] flex items-center justify-center">
                                        <img src={IconAvailabilityOff} />
                                    </div>
                                )
                            }
                            hover={
                                available?.job_options.available_for_work ||
                                !isAuth ||
                                !info?.first_name ? (
                                    <div className="min-w-[40px] h-[40px] flex items-center justify-center">
                                        <img src={IconAvailabilityOnHover} />
                                    </div>
                                ) : (
                                    <div className="min-w-[40px] h-[40px] flex items-center justify-center">
                                        <img src={IconAvailabilityOffHover} />
                                    </div>
                                )
                            }
                        />
                    }
                    position={isMobileVersion ? 'right' : 'center'}
                    className={'w-[230px]'}
                    opened={showAvailableForWorkNotification}
                    showBackdrop={showAvailableForWorkNotification}
                    onClose={() => dispatch(setAvailableForWorkNotify(false))}
                    showAlert={showAvailableForWorkNotification}
                    alert={
                        'Please turn on “Available for work” to be able to apply for the job'
                    }
                >
                    <li className="p-6 cursor-pointer border-b border-gray-4">
                        <Switch
                            id={'available_for_work'}
                            label={'Available for work'}
                            checked={forWork}
                            disabled={!disableAvailableToWork}
                            onChange={(value) => handlerSwitchAvailable(value)}
                        />
                    </li>
                    {info?.first_name && info?.last_name ? (
                        <li className="p-6 cursor-pointer">
                            {available?.job_options && (
                                <RadioButtons
                                    jobOptions={available?.job_options}
                                />
                            )}
                        </li>
                    ) : null}
                </DropdownMenu>
            </div>*/}
            {/*<div
                className={`header-tools-lang flex items-center min-w-[24px] mr-4 ${
                    info?.first_name && info?.last_name
                        ? 'block'
                        : 'hidden md:block'
                } hidden`}
            >
                <DropdownMenu
                    parent={
                        <Icon
                            normal={<img src={IconLang} />}
                            hover={<img src={IconLangHover} />}
                        />
                    }
                    position={'right'}
                    className={'w-[200px]'}
                >
                    <li className="px-5 py-4">
                        <span className="font-semibold text-sm text-dark-5 cursor-pointer">
                            English
                        </span>
                    </li>
                    <li className="px-5 py-4">
                        <span className="font-semibold text-sm text-dark-5 cursor-pointer">
                            Norsk
                        </span>
                    </li>
                    <li className="px-5 py-4">
                        <span className="font-semibold text-sm text-dark-5 cursor-pointer">
                            Svenska
                        </span>
                    </li>
                    <li className="px-5 py-4">
                        <span className="font-semibold text-sm text-dark-5 cursor-pointer">
                            Dansk
                        </span>
                    </li>
                    <li className="px-5 py-4">
                        <span className="font-semibold text-sm text-dark-5 cursor-pointer">
                            Suomalainen
                        </span>
                    </li>
                </DropdownMenu>
            </div>*/}
            <div className="header-tools-profile">
                {info?.first_name && info?.last_name ? (
                    <div className="hidden md:block">
                        <DropdownMenu
                            parent={
                                <a
                                    href={'/profile'}
                                    className={`flex items-center py-1.5 pl-4 pr-1.5 rounded-[128px] cursor-pointer transition-all hover:bg-background-2 ${
                                        url === '/profile'
                                            ? 'bg-background-2'
                                            : 'bg-white'
                                    }`}
                                >
                                    <span className="mr-3 font-semibold text-sm">
                                        {!isLoading &&
                                            formatFullName(
                                                `${info?.first_name} ${info?.last_name}`
                                            )}
                                    </span>
                                    <div className="header-tools-profile-avatar w-6 h-6 overflow-hidden rounded-full">
                                        <img
                                            src={
                                                info?.avatar
                                                    ? info?.avatar
                                                    : avatarUrl
                                            }
                                            alt="avatar"
                                        />
                                    </div>
                                </a>
                            }
                            position={'right'}
                            className={'w-[200px]'}
                        >
                            <li className="px-5 py-4 border-b border-gray-4">
                                {/* <a
                                href={'/profile'}
                                className="block font-semibold text-sm text-dark-5 cursor-pointer mb-5"
                            >
                                Profile
                            </a> */}
                                <a
                                    href={'/settings'}
                                    className="block font-semibold text-sm text-dark-5 cursor-pointer"
                                >
                                    Settings
                                </a>
                            </li>
                            <li className="px-5 py-4 border-b border-gray-4">
                                <a
                                    href="/saved-jobs"
                                    className="block font-semibold text-sm text-dark-5 cursor-pointer"
                                >
                                    Saved
                                </a>
                            </li>
                            <li className="px-5 py-4">
                                <a
                                    href="/contact"
                                    className="block font-semibold text-sm text-dark-5 mb-5 cursor-pointer"
                                >
                                    Help
                                </a>
                                <span
                                    onClick={() => handlerLogout()}
                                    className="block font-semibold text-sm text-dark-5 cursor-pointer"
                                >
                                    Logout
                                </span>
                            </li>
                        </DropdownMenu>
                    </div>
                ) : (
                    <div className="btns-wrap w-[180px] flex items-center justify-between">
                        <div className="w-[84px]">
                            <Button
                                type={'button'}
                                view={'transparent'}
                                title={'Log In'}
                                className={
                                    '!rounded-full !h-[36px] lg:!h-[44px]'
                                }
                                onClick={() =>
                                    (location.href = `${process.env.API_URL}/login`)
                                }
                            />
                        </div>
                        <div className="w-[84px]">
                            <Button
                                type={'button'}
                                view={'primary'}
                                title={'Sign Up'}
                                className={
                                    '!rounded-full !h-[36px] lg:!h-[44px]'
                                }
                                onClick={() =>
                                    (location.href = `${process.env.API_URL}/register`)
                                }
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
