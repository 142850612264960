import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ILoggedIn, ILogin } from './models/login/models';

export const loginApi = createApi({
    reducerPath: 'loginApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.API_URL,
        prepareHeaders: (headers, {endpoint}) => {
            if (endpoint === 'isAuth') {
                headers.set('Accept', 'application/json')
            }
            const token = document.getElementsByName('csrf-token')[0]?.getAttribute('content')
            if (token) headers.set('X-CSRF-TOKEN', token)
            headers.set('content-type', 'application/json')

            return headers
        },
        credentials: process.env.NODE_ENV === 'development' ? 'include' : 'same-origin'
    }),
    endpoints: (builder) => ({
        login: builder.mutation<ILoggedIn, ILogin>({
            query: (body) => ({
                url: '/login/via/email',
                method: 'POST',
                body
            })
        }),
        logout: builder.mutation<{logged_out: boolean, redirect_to: string}, any>({
            query: () => ({
                url: '/logout',
                method: 'POST'
            })
        }),
        isAuth: builder.query<any, any>({
            query: () => ({
                url: '/auth-check',
                method: 'GET',
            }),
            transformResponse: (data, error) => {
                return {
                    status: error?.response?.status === 200 ? true : false
                }
            }
        })
    }),
});


export const { useLoginMutation, useLogoutMutation, useIsAuthQuery } = loginApi

