import { FC, useEffect, useState } from 'react'
import { useAppSelector } from '../../../app/hooks/hooks'
import { isMobile, isTablet } from '../../../app/slices/profile'
import { IDropdownMenu } from './DropdownMenu.model'
import s from './DropdownMenu.module.css'

export const DropdownMenu: FC<IDropdownMenu> = ({
    parent,
    children,
    position,
    className,
    opened = false,
    showBackdrop = false,
    alert = '',
    showAlert = false,
    onClose,
}) => {
    const isTabletVersion = useAppSelector(isTablet)
    const [isOpened, setIsOpened] = useState<boolean>(opened)

    const dropdownPositionHandler = () => {
        let classes = ''

        if (position === 'center') {
            classes = 'left-[50%] translate-x-[-50%]'
        } else if (position === 'right') {
            classes = 'right-[-16px]'
        } else if (position === 'top-right') {
            classes = 'right-[-16px] -top-[280px]'
        }

        return classes
    }

    const closeBackdrop = () => {
        onClose && onClose()
    }

    useEffect(() => {
        setIsOpened(opened)
    }, [opened])

    return (
        <>
            <div
                className={`${
                    showBackdrop && opened ? 'z-[52]' : 'z-[50]'
                } group relative`}
            >
                <button
                    className={`bg-gray-300 flex items-center ${
                        showBackdrop ? 'rounded-full overflow-hidden' : ''
                    }`}
                    onClick={() => setIsOpened((prev) => !prev)}
                >
                    {parent}
                </button>
                {isTabletVersion ? (
                    <div
                        className={`${dropdownPositionHandler()} absolute ${
                            isOpened ? 'block' : 'hidden'
                        } ${className}`}
                    >
                        <ul
                            className={`${
                                position === 'center'
                                    ? s.dropdown
                                    : s.dropdownRight
                            } mt-5`}
                        >
                            {children}
                        </ul>
                        {showAlert && (
                            <div
                                className={`${s.alert} bg-[#F16422] rounded-[8px] mt-1 p-2 text-sm text-center font-semibold text-white`}
                            >
                                {alert}
                            </div>
                        )}
                    </div>
                ) : (
                    <div
                        className={`${dropdownPositionHandler()} absolute ${
                            !isOpened ? 'hidden group-hover:block' : 'block'
                        } ${className}`}
                    >
                        <ul
                            className={`${
                                position === 'center'
                                    ? s.dropdown
                                    : s.dropdownRight
                            } mt-5`}
                        >
                            {children}
                        </ul>
                        {showAlert && (
                            <div
                                className={`${s.alert} bg-[#F16422] rounded-[8px] mt-1 p-2 text-sm text-center font-semibold text-white`}
                            >
                                {alert}
                            </div>
                        )}
                    </div>
                )}
            </div>
            {showBackdrop && (
                <div
                    className="fixed left-0 top-0 w-full h-full bg-dark-5 opacity-40 z-[51]"
                    onClick={() => closeBackdrop()}
                />
            )}
        </>
    )
}
