import { FC } from 'react'
import { useAppSelector } from '../../../app/hooks/hooks'
import { isMobile } from '../../../app/slices/profile'
import { IButton } from './Button.model'
import styles from './Button.module.css'

export const Button: FC<IButton> = ({
    title,
    type,
    view = 'primary',
    icon,
    iconPosition,
    disabled = false,
    onClick,
    className,
    iconClassName,
}) => {
    return (
        <button
            className={`${styles[view]} ${styles.btn} flex ${
                iconPosition === 'right' || iconPosition === 'left'
                    ? 'justify-between'
                    : 'justify-center'
            } w-full items-center rounded px-3.5 py-3 text-sm font-semibold leading-5 transition ease-out duration-300 ${className}`}
            disabled={disabled}
            onClick={onClick}
            type={type}
        >
            {icon && iconPosition === 'left' && (
                <span className={`mr-3 min-w-[16px] ${iconClassName}`}>
                    {icon}
                </span>
            )}
            {icon && iconPosition === 'center' && (
                <span className={`mr-3 min-w-[16px] ${iconClassName}`}>
                    {icon}
                </span>
            )}
            {title}
            {icon && iconPosition === 'right' && icon}
        </button>
    )
}
