import { FC } from 'react'
import IconFacebook from '../../../core/assets/icons/IconFacebookCircle.svg'
import IconLinkedin from '../../../core/assets/icons/IconLinkedinCircle.svg'
import IconTwitter from '../../../core/assets/icons/IconTwitterCircle.svg'
import IconFacebookMobile from '../../../core/assets/icons/icon-fb-mobile.svg'
import IconLinkedinMobile from '../../../core/assets/icons/icon-linkedin-mobile.svg'
import IconTwitterMobile from '../../../core/assets/icons/icon-twitter-mobile.svg'
import IconInstagram from '../../../core/assets/icons/IconInstagram.svg'
import IconInstagramMobile from '../../../core/assets/icons/IconInstagramMobile.svg'
import logoRecman from '../../../core/assets/images/logo-recman.svg'
import { useAppSelector } from '../../app/hooks/hooks'
import { isMobile } from '../../app/slices/profile'

export const Footer: FC = () => {
    const isMobileVersion = useAppSelector(isMobile)
    return (
        <footer>
            <div className="container mx-auto px-4 lg:px-[40px] xl:px-0">
                <div className="flex justify-between flex-col md:flex-row pt-8 md:pt-6 xl:pt-12 mb-[32px] xl:mb-[72px]">
                    <div className="desc mb-6 md:mb-0 max-w-[100%] md:max-w-[160px] xl:max-w-[280px]">
                        <span className="font-medium text-sm text-black">
                            Get headhunted
                        </span>
                        <p className="text-xs text-dark-5 mt-2">
                            In JobReg.no you register only once, and then you
                            can apply for jobs with only one click, or you can
                            wait to be hadhunted from your background and
                            competence.
                        </p>
                    </div>
                    <div className="links mb-6 md:mb-0 max-w-[100%] md:max-w-[166px] xl:max-w-[200px]">
                        <span className="font-medium text-sm text-black">
                            Privacy
                        </span>
                        <div className="mt-2">
                            <a
                                href={`${process.env.API_URL}/policy/terms-of-service`}
                                className="block text-dark-5 text-xs leading-6"
                            >
                                Terms of Service
                            </a>
                            {/* <a
                                href="/responsible"
                                className="block text-dark-5 text-sm leading-6"
                            >
                                Responsible Disclosure Policy
                            </a> */}
                            <a
                                href={`${process.env.API_URL}/policy/privacy-policy`}
                                className="block text-dark-5 text-xs leading-6"
                            >
                                Privacy Policy
                            </a>
                        </div>
                    </div>
                    <div className="links mb-6 md:mb-0 max-w-[100%] md:max-w-[112px] xl:max-w-[130px]">
                        <span className="font-medium text-sm text-black">
                            Contacts
                        </span>
                        <div className="mt-2 text-dark-5 text-xs leading-6">
                            Karenslyst allé 8B 0287 OSLO, Norway post@jobreg.no
                        </div>
                        <div className="mt-8 max-w-[150px] flex justify-between align-center">
                            <a
                                href="https://www.facebook.com/Jobreg/"
                                className="flex items-center text-dark-5 text-sm leading-6"
                            >
                                <img
                                    src={
                                        !isMobileVersion
                                            ? IconFacebook
                                            : IconFacebookMobile
                                    }
                                    alt="icon facebook"
                                />
                            </a>
                            <a
                                href="https://www.linkedin.com/company/5050734"
                                className="flex items-center text-dark-5 text-sm leading-6"
                            >
                                <img
                                    src={
                                        !isMobileVersion
                                            ? IconLinkedin
                                            : IconLinkedinMobile
                                    }
                                    alt="icon linkedin"
                                />
                            </a>
                            <a
                                href="https://www.instagram.com/jobreg.no/"
                                className="flex items-center text-dark-5 text-sm leading-6"
                            >
                                <img
                                    src={
                                        !isMobileVersion
                                            ? IconInstagram
                                            : IconInstagramMobile
                                    }
                                    alt="icon linkedin"
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="border-b border-gray-4" />
                <div className="flex justify-between items-center py-6">
                    <p className="text-xs text-dark-2">Jobreg © 2023</p>
                    <div className="min-w-[80px] hidden md:block" />
                </div>
            </div>
        </footer>
    )
}
