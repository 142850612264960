import { FC, useEffect, useState } from 'react'
import { useGetSearchOptionsQuery } from '../../../core/app/api/availableJobs'
import { Button } from '../../../core/components/common/Button/Button'
import { Input } from '../../../core/components/common/Input/Input'
import { Select } from '../../../core/components/common/Select/Select'

export const SectionSearch: FC = () => {
    const { data: options } = useGetSearchOptionsQuery({})
    const [searchQuery, setSearchQuery] = useState<string>('')
    const [industries, setIndustries] = useState<
        { id: number; title: string; icon: string }[]
    >([])
    const [locations, setLocations] = useState<
        { id: number; title: string; icon: string }[]
    >([])
    const [selectedIndustry, setSelectedIndustry] = useState<{
        id: string
        title: string
        image: string
    }>()
    const [selectedLocation, setSelectedLocation] = useState<{
        id: string
        title: string
        icon: string
    }>()

    const searchHandler = () => {
        const url = new URL(`${process.env.API_URL}/available-jobs`)
        selectedIndustry &&
            url.searchParams.append('industry_category', selectedIndustry?.id)
        selectedLocation &&
            url.searchParams.append('country', selectedLocation?.id)
        searchQuery.length > 2 && url.searchParams.append('search', searchQuery)
        location.href = url.toString()
    }

    useEffect(() => {
        if (options) {
            industries.length === 0 &&
                options.options.industries.map((industry) => {
                    let newObj = {
                        id: industry.industry_category_id,
                        title: industry.name,
                        icon: industry.image,
                    }
                    setIndustries((prev) => [...prev, newObj])
                })

            locations.length === 0 &&
                options.options.locations.map((location) => {
                    let newObj = {
                        id: location.country_id,
                        title: location.name,
                        icon: location.flag,
                    }
                    setLocations((prev) => [...prev, newObj])
                })
        }
    }, [options])

    useEffect(() => {
        console.log(options)
    }, [options])

    return (
        <div className="container mx-auto">
            <h2 className="text-[32px] leading-[44px] lg:text-[40px] lg:leading-[60px] font-semibold">
                Looking for a job?
            </h2>
            <div className="mt-8 flex items-center flex-col md:flex-row gap-2 md:gap-0">
                <div className="w-full lg:w-[352px] md:w-[25%]">
                    <Input
                        type={'text'}
                        placeholder={'Search'}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        id={'search_field'}
                        className={
                            '!h-[72px] rounded border-0 md:rounded-none !text-base placeholder:text-base md:border-r focus:border max-w-full'
                        }
                    />
                </div>
                <div className="w-full lg:w-[352px] md:w-[25%]">
                    {options?.options.industries && (
                        <Select
                            data={industries}
                            onChange={(value) => setSelectedIndustry(value)}
                            placeholder={'Industries'}
                            className={
                                '!h-[72px] border-0 md:border-r md:rounded-none text-base'
                            }
                            dropdownClassName={
                                '!top-[72px] !max-h-[156px] !w-full max-w-full'
                            }
                            openedListClassName={'!border'}
                            iconClassName={'max-w-[24px]'}
                        />
                    )}
                </div>
                <div className="w-full lg:w-[352px] md:w-[25%]">
                    {options?.options.locations && (
                        <Select
                            data={locations}
                            onChange={(value) => setSelectedLocation(value)}
                            placeholder={'Locations'}
                            className={
                                '!h-[72px] border-0 md:rounded-none text-base'
                            }
                            dropdownClassName={
                                '!top-[72px] !max-h-[156px] !w-full max-w-full'
                            }
                            openedListClassName={'!border'}
                            iconClassName={'max-w-[24px]'}
                        />
                    )}
                </div>
                <div className={'w-full md:w-[25%] lg:w-[160px]'}>
                    <Button
                        type={'button'}
                        view={'primary'}
                        title={'Search'}
                        className={
                            'text-base !h-[48px] rounded md:rounded-none md:!h-[72px]'
                        }
                        onClick={() => searchHandler()}
                    />
                </div>
            </div>
        </div>
    )
}
