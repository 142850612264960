import { FC } from 'react'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useLogoutMutation } from '../../app/api/loginApi'
import { useGetHeaderInfoQuery } from '../../app/api/profileApi'
import { useAppDispatch, useAppSelector } from '../../app/hooks/hooks'
import { logged, setLogged } from '../../app/slices/signup'
import { DropdownMenu } from '../common/DropdownMenu/DropdownMenu'
import IconHome from '../../assets/icons/icon_home_mobile.svg'
import IconJobs from '../../assets/icons/icon_jobs_mobile.svg'
import avatarUrl from '../../assets/images/avatar.svg'
import { isMobile, isTablet, isTabletAir } from '../../app/slices/profile'

export const MobileStickyFooter: FC = () => {
    const dispatch = useAppDispatch()
    const isAuth = useAppSelector(logged)
    const isTabletVersion = useAppSelector(isTablet)
    const isTabletAirVersion = useAppSelector(isTabletAir)
    const isMobileVersion = useAppSelector(isMobile)
    const { data: info } = useGetHeaderInfoQuery(!isAuth ? skipToken : {})
    const [logout] = useLogoutMutation()

    const handlerLogout = async () => {
        try {
            const response = await logout({}).unwrap()

            dispatch(setLogged(false))
            window.location.href = response.redirect_to
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div
            className={`${
                isTabletVersion || isTabletAirVersion || isMobileVersion
                    ? 'block'
                    : 'hidden'
            } sticky bottom-0 w-full bg-white border-t border-gray-3 py-4 px-[50px] z-[50]`}
        >
            <div className="flex items-center justify-between w-full">
                <a href={`${process.env.API_URL}`}>
                    <img src={IconHome} alt="icon home" />
                    <div className="text-[10px] text-dark-4 font-medium text-center mt-1">
                        Home
                    </div>
                </a>
                <a href={`${process.env.API_URL}/available-jobs`}>
                    <img src={IconJobs} alt="icon jobs" />
                    <div className="text-[10px] text-dark-4 font-medium text-center mt-1">
                        Jobs
                    </div>
                </a>
                <DropdownMenu
                    parent={
                        // <a href={`${process.env.API_URL}/profile`}>
                        <div className="flex flex-col">
                            <img
                                src={info?.avatar || avatarUrl}
                                alt="icon profile"
                                className="mx-auto w-6 h-6 rounded-full overflow-hidden"
                            />
                            <div className="text-[10px] text-dark-4 font-medium text-center mt-1">
                                Profile
                            </div>
                        </div>

                        // </a>
                    }
                    position={'top-right'}
                    className={'w-[200px]'}
                >
                    <li className="px-5 py-4 border-b border-gray-4">
                        <a
                            href={'/profile'}
                            className="block font-semibold text-sm text-dark-5 cursor-pointer mb-5"
                        >
                            Profile
                        </a>
                        <a
                            href={'/settings'}
                            className="block font-semibold text-sm text-dark-5 cursor-pointer"
                        >
                            Settings
                        </a>
                    </li>
                    <li className="px-5 py-4 border-b border-gray-4">
                        <a
                            href="/saved-jobs"
                            className="block font-semibold text-sm text-dark-5 cursor-pointer"
                        >
                            Saved
                        </a>
                    </li>
                    <li className="px-5 py-4">
                        <a
                            href="/contact"
                            className="block font-semibold text-sm text-dark-5 mb-5 cursor-pointer"
                        >
                            Help
                        </a>
                        <span
                            onClick={() => handlerLogout()}
                            className="block font-semibold text-sm text-dark-5 cursor-pointer"
                        >
                            Logout
                        </span>
                    </li>
                </DropdownMenu>
            </div>
        </div>
    )
}
