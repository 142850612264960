import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store/store'
import setLocation from '../../../core/app/helpers/urlPush'

export interface IAvailableJobs {
    queryString: string
    url_country: string | string[] | null
    url_area: string | string[] | null
    url_city: string | string[] | null
    url_industry_category: string | string[] | null
    url_industry: string | string[] | null
    url_employee_type: string | string[] | null
    search_query: string
    url_page: string
    url_order_by: string
    url_per_page: string
    url_suggested: string
    url_suggested_query: string
}

const initialState: IAvailableJobs = {
    queryString: `${process.env.API_URL}/available-jobs`,
    url_country: '',
    url_area: '',
    url_city: '',
    url_industry_category: '',
    url_industry: '',
    url_employee_type: '',
    search_query: '',
    url_page: '',
    url_order_by: '',
    url_per_page: '',
    url_suggested: '',
    url_suggested_query: '',
}

const availableJobs = createSlice({
    name: 'availableJobs',
    initialState,
    reducers: {
        setQueryParams: (state) => {
            const query = new URL(state.queryString)
            query.search = `${state.url_country}${state.url_area}${
                state.url_city
            }${state.url_industry_category}${state.url_industry}${
                state.url_employee_type
            }${state.search_query}${state.url_page}${state.url_order_by}${
                state.url_per_page
            }${state.url_suggested ? state.url_suggested : ''}${
                state.url_suggested_query ? state.url_suggested_query : ''
            }`.substring(1)
            state.queryString = query.href
            setLocation(query.href)
        },
        setSuggested: (state, action: PayloadAction<boolean>) => {
            if (action.payload) {
                state.url_suggested = `&suggested=${action.payload}`
            } else {
                state.url_suggested = ''
            }
        },
        setCountryFilter: (state, action: PayloadAction<string[] | null>) => {
            let country_string: string = '&country='
            if (action.payload === null || action.payload.length === 0) {
                state.url_country = action.payload
                return
            } else {
                action.payload.map((filter: string) => {
                    country_string = `${country_string}${filter.split('-')[1]}${
                        action.payload &&
                        action.payload.length !==
                            action.payload.indexOf(filter) + 1
                            ? ','
                            : ''
                    }`
                })
            }
            state.url_country = country_string
        },
        setAreaFilter: (state, action: PayloadAction<string[] | null>) => {
            let area_string: string = '&region='
            if (action.payload === null || action.payload.length === 0) {
                state.url_area = action.payload
                return
            } else {
                action.payload.map((filter: string) => {
                    area_string = `${area_string}${filter.split('-')[1]}${
                        action.payload &&
                        action.payload.length !==
                            action.payload.indexOf(filter) + 1
                            ? ','
                            : ''
                    }`
                })
            }
            state.url_area = area_string
        },
        setCityFilter: (state, action: PayloadAction<string[] | null>) => {
            let city_string: string = '&city='
            if (action.payload === null || action.payload.length === 0) {
                state.url_city = action.payload
                return
            } else {
                action.payload.map((filter: string) => {
                    city_string = `${city_string}${filter.split('-')[1]}${
                        action.payload &&
                        action.payload.length !==
                            action.payload.indexOf(filter) + 1
                            ? ','
                            : ''
                    }`
                })
            }
            state.url_city = city_string
        },
        setIndustryCategoryFilter: (
            state,
            action: PayloadAction<string[] | null>
        ) => {
            let category_string: string = '&industry_category='
            if (action.payload === null || action.payload.length === 0) {
                state.url_industry_category = action.payload
                return
            } else {
                action.payload.map((filter: string) => {
                    category_string = `${category_string}${
                        filter.split('-')[1]
                    }${
                        action.payload &&
                        action.payload.length !==
                            action.payload.indexOf(filter) + 1
                            ? ','
                            : ''
                    }`
                })
            }
            state.url_industry_category = category_string
        },
        setIndustryFilter: (state, action: PayloadAction<string[] | null>) => {
            let industry_category: string = '&industry='
            if (action.payload === null || action.payload.length === 0) {
                state.url_industry = action.payload
                return
            } else {
                action.payload.map((filter: string) => {
                    industry_category = `${industry_category}${
                        filter.split('-')[1]
                    }${
                        action.payload &&
                        action.payload.length !==
                            action.payload.indexOf(filter) + 1
                            ? ','
                            : ''
                    }`
                })
            }
            state.url_industry = industry_category
        },
        setEmployeeTypeFilter: (
            state,
            action: PayloadAction<string[] | null>
        ) => {
            let employee_type: string = '&employee_type='
            if (action.payload === null || action.payload.length === 0) {
                state.url_employee_type = action.payload
                return
            } else {
                action.payload.map((filter: string) => {
                    employee_type = `${employee_type}${filter.split('-')[1]}${
                        action.payload &&
                        action.payload.length !==
                            action.payload.indexOf(filter) + 1
                            ? ','
                            : ''
                    }`
                })
            }
            state.url_employee_type = employee_type
        },
        setSearchValue: (state, action: PayloadAction<string>) => {
            if (action.payload.length === 0) state.search_query = ''
            else state.search_query = `&search=${action.payload}`
        },
        setPage: (state, action: PayloadAction<number>) => {
            if (action.payload === 0) {
                state.url_page = ''
            } else {
                state.url_page = `&page=${action.payload}`
            }
        },
        setOrder: (state, action: PayloadAction<string>) => {
            state.url_order_by = `&order_by=${action.payload}`
        },
        setPostsPerPage: (state, action: PayloadAction<string>) => {
            state.url_per_page = `&per_page=${action.payload}`
        },
        setSuggestedQuery: (state, action: PayloadAction<string>) => {
            state.url_per_page = action.payload
        },
    },
})

export const {
    setQueryParams,
    setCountryFilter,
    setAreaFilter,
    setCityFilter,
    setIndustryCategoryFilter,
    setIndustryFilter,
    setEmployeeTypeFilter,
    setSearchValue,
    setPage,
    setOrder,
    setPostsPerPage,
    setSuggested,
    setSuggestedQuery,
} = availableJobs.actions
export const url_per_page = (state: RootState) =>
    state.availableJobs.url_per_page
export const url_page = (state: RootState) => state.availableJobs.url_page
export const url_order_by = (state: RootState) =>
    state.availableJobs.url_order_by
export const queryString = (state: RootState) => state.availableJobs.queryString
export const url_country = (state: RootState) => state.availableJobs.url_country
export const url_region = (state: RootState) => state.availableJobs.url_area
export const url_city = (state: RootState) => state.availableJobs.url_city
export const url_industry_category = (state: RootState) =>
    state.availableJobs.url_industry_category
export const url_industry = (state: RootState) =>
    state.availableJobs.url_industry
export const url_employee_type = (state: RootState) =>
    state.availableJobs.url_employee_type
export const search_query = (state: RootState) =>
    state.availableJobs.search_query
export const url_suggested = (state: RootState) =>
    state.availableJobs.url_suggested
export default availableJobs.reducer
