import { FC, useEffect, useState } from 'react'
import { RefreshPageModal } from '../core/components/common/RefreshPageModal/RefreshPageModal'
import { Footer } from '../core/components/Footer/Footer'
import { Header } from '../core/components/Header/Header'
import { SectionCompanies } from './components/section-companies/SectionCompanies'
import { SectionCover } from './components/section-cover/SectionCover'
import { SectionIndustries } from './components/section-industries/SectionIndustries'
import { SectionJobs } from './components/section-jobs/SectionJobs'
import { SectionLocations } from './components/section-locations/SectionLocations'
import { SectionProfile } from './components/section-profile/SectionProfile'
import { SectionPromoute } from './components/section-promoute/SectionPromoute'
import { SectionSearch } from './components/section-search/SectionSearch'
import { Layout } from '../core/components/common/Layout/Layout'

export const LandingPage: FC = () => {
    return (
        <Layout
            content={
                <main>
                    <section
                        id={'cover'}
                        className={
                            'px-[25px] py-[40px] md:pb-[44px] md:pl-[44px] lg:pl-[64px] lg:pr-[15px] lg:pt-0 lg:pb-[85px] xl:px-4 xl:py-[68px]'
                        }
                    >
                        <SectionCover />
                    </section>
                    <section
                        id={'search'}
                        className={
                            'px-[25px] py-[80px] md:px-[44px] lg:px-[64px] xl:px-4 xl:pt-[120px] xl:pb-[75px] bg-[#EFF5FE]'
                        }
                    >
                        <SectionSearch />
                    </section>
                    <section
                        id={'industires'}
                        className={
                            'px-[25px] pb-[80px] md:px-[44px] pd:mb-[80px] lg:px-[64px] xl:px-4 pb-[120px] bg-[#EFF5FE]'
                        }
                    >
                        <SectionIndustries />
                    </section>
                    <section
                        id={'jobs'}
                        className={
                            'px-[25px] py-[80px] md:px-[44px] md:py-[80px] lg:px-[64px] xl:px-4 xl:py-[120px] bg-[#C6E0FF]'
                        }
                    >
                        <SectionJobs />
                    </section>
                    <section
                        id={'locations'}
                        className={
                            'px-[25px] py-[80px] md:px-[44px] md:py-[80px] lg:px-[64px] xl:xl:px-4 xl:py-[80px] bg-[#EFF5FE]'
                        }
                    >
                        <SectionLocations />
                    </section>
                    {/* <section id={'promoute'} className={'py-[120px] bg-[#C6E0FF]'}>
                    <SectionPromoute />
                </section> */}
                    <section
                        id={'profile'}
                        className={
                            'px-[25px] py-[80px] md:px-[44px] md:py-[80px] lg:px-[64px] xl:px-4 py-[80px] bg-[#C6E0FF]'
                        }
                    >
                        <SectionProfile />
                    </section>
                    <section
                        id={'companies'}
                        className={
                            'px-[25px] py-[80px] md:px-[44px] md:py-[80px] lg:px-[64px] xl:px-4 py-[120px] bg-[#3E2C70]'
                        }
                    >
                        <SectionCompanies />
                    </section>
                </main>
            }
        />
    )
}
