import { FC, useEffect, useRef } from 'react'
import { useGetPopularIndustriesQuery } from '../../../core/app/api/availableJobs'
import { Button } from '../../../core/components/common/Button/Button'
import Slider from 'react-slick'
import IconArrowBack from '../../../core/assets/icons/IconArrowBack.svg'
import industryImage from '../../assets/images/industry.svg'
import illustratioImage from '../../assets/images/illustration.svg'
import styles from './SectionIndustries.module.css'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination } from 'swiper'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'

SwiperCore.use([Navigation])

const CustomSlide = (props: {
    id: number
    name: any
    count: number
    image: string
}) => {
    const { name, id, count, image } = props
    const chooseIndustryHandler = (id: number) => {
        const url = new URL(`${process.env.API_URL}/available-jobs`)
        url.searchParams.append('industry_category', id.toString())
        location.href = url.toString()
    }

    return (
        <div
            className={`${styles.card} w-full px-2 xl:mx-0 h-[344px]`}
            key={id}
        >
            <div className={styles.inner} />
            <div
                className={`${styles.content} p-6 bg-white flex flex-col text-center`}
            >
                <div className="relative mx-auto w-[88px] h-[88px] bg-background-5 rounded-full mt-4 flex items-center justify-center">
                    <img
                        src={image}
                        className={'w-9 h-9'}
                        alt="industry logo"
                    />
                    <span className="absolute bottom-0 left-0">
                        <img src={illustratioImage} alt="illustration" />
                    </span>
                </div>
                <h3 className="mt-6 text-xl font-semibold text-center">
                    {name}
                </h3>
                <span className="mt-2 text-sm text-dark-4">{count} jobs</span>
                <div className="mt-auto">
                    <Button
                        type={'button'}
                        view={'additional'}
                        title={'View Jobs'}
                        onClick={() => chooseIndustryHandler(id)}
                        className={'text-sm !hover:bg-primary'}
                    />
                </div>
            </div>
        </div>
    )
}

export const SectionIndustries: FC = () => {
    const { data: popularIndustries } = useGetPopularIndustriesQuery({})

    const chooseIndustryHandler = (id: number) => {
        const url = new URL(`${process.env.API_URL}/available-jobs`)
        url.searchParams.append('industry_category', id.toString())
        location.href = url.toString()
    }

    return (
        <div className="container mx-auto">
            <div className="flex justify-between items-end md:items-center md:mb-[24px] lg:mb-[48px]">
                <h2 className="text-[32px] leading-[44px] lg:text-[40px] xl:leading-[60px] font-semibold">
                    Popular Industries
                </h2>
                <div className="xl:hidden min-w-[112px] w-[112px] h-[48px] relative">
                    <span
                        className={`w-12 h-12 rounded-full bg-white select-none absolute top-[50%] -translate-y-[50%] left-0 z-50 flex items-center justify-center cursor-pointer prev-el`}
                    >
                        <img src={IconArrowBack} />
                    </span>
                    <span
                        className={`w-12 h-12 rounded-full bg-white select-none absolute top-[50%] -translate-y-[50%] right-0 z-50 flex items-center justify-center cursor-pointer next-el`}
                    >
                        <img src={IconArrowBack} className={'rotate-180'} />
                    </span>
                </div>
                <div className="hidden xl:block w-[160px]">
                    <Button
                        type={'button'}
                        view={'additional'}
                        title={'See all jobs'}
                        onClick={() =>
                            (location.href = `${process.env.API_URL}/available-jobs`)
                        }
                        className={'hover:!bg-primary hover:!text-white'}
                    />
                </div>
            </div>
            <div className="xl:hidden overflow-y-hidden mt-4 -mr-[25px] md:mt-0 md:mr-0 h-[340px]">
                <Swiper
                    navigation={{
                        nextEl: '.next-el',
                        prevEl: '.prev-el',
                    }}
                    slidesPerView={1.5}
                    spaceBetween={20}
                    loop={true}
                    pagination={{
                        clickable: true,
                    }}
                    className="mySwiper"
                    modules={[Navigation]}
                    breakpoints={{
                        640: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                        },
                    }}
                >
                    {popularIndustries &&
                        popularIndustries.popular_industries.map((industry) => (
                            <SwiperSlide>
                                <CustomSlide
                                    key={industry.industry_category_id}
                                    id={industry.industry_category_id}
                                    name={industry.name}
                                    count={industry.count}
                                    image={industry.image}
                                />
                            </SwiperSlide>
                        ))}
                </Swiper>
            </div>
            {popularIndustries && (
                <div className="hidden xl:flex justify-between">
                    {popularIndustries.popular_industries.map((industry) => (
                        <div
                            className={`${styles.card} w-[280px] h-[344px] group`}
                            key={industry.industry_category_id}
                        >
                            <div className={styles.inner} />
                            <div
                                className={`${styles.content} p-6 bg-white flex flex-col text-center`}
                            >
                                <div className="relative mx-auto w-[88px] h-[88px] mt-4 flex items-center justify-center bg-background-5 rounded-full">
                                    <img
                                        src={industry.image}
                                        className={'w-9 h-9'}
                                        alt="industry logo"
                                    />
                                    <span className="absolute bottom-0 left-0">
                                        <img
                                            src={illustratioImage}
                                            alt="illustration"
                                        />
                                    </span>
                                </div>
                                <h3 className="mt-6 text-xl font-semibold text-center">
                                    {industry.name}
                                </h3>
                                <span className="mt-2 text-sm text-dark-4">
                                    {industry.count} jobs
                                </span>
                                <div className="mt-auto">
                                    <Button
                                        type={'button'}
                                        view={'additional'}
                                        title={'View Jobs'}
                                        onClick={() =>
                                            chooseIndustryHandler(
                                                industry.industry_category_id
                                            )
                                        }
                                        className={
                                            'text-sm hover:!bg-primary hover:!text-white'
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}
